import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import * as echarts from 'echarts'
import animated from 'animate.css'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router' 
import $ from 'jquery'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(animated);
Vue.use($);
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
