<template>
  <div class="login">
      <div class="login_top animate__animated animate__fadeInDownBig">
        <div class="login_top_left">
            <img src="../../img/login_left_bg.png" alt="">
        </div>
        <div class="login_top_mid">
            <img src="../../img/login.png" alt="">
        </div>
        <div class="login_top_right">
          <img src="../../img/login_right_bg.png" alt="">
          <img src="../../img/tuichu.png" alt="">
        </div>
      </div>
      <div class="login_con animate__animated animate__fadeIn" >
          <div class="login_kuang">
            <div class="shuru">
              <div class="shuru1">
                <input type="text" placeholder="用户名">
              </div>
              <div class="shuru1">
                <input type="text" placeholder="密码">
              </div>
              <div class="shuru2">
                <div class="btn" @click="loginBtn">
                  <p>登录</p>
                </div>
                <div class="btn">
                  <p>注册</p>
                </div>
              </div>
            </div>
          </div>
      </div>
      <footerVue></footerVue>
  </div>
</template>

<script>
import footerVue from '../../components/footerBar.vue'
export default {
  name:'login',
  components:{
    footerVue,
  },
  data(){
    return{

    }
  },
  methods:{
    loginBtn(){
      this.$router.push({
        name:'index',
        params:{

        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .login{
    position: relative;
    width: calc(100vw * 1920 / 1920);
    height: calc(100vh * 1080 / 1080);
    overflow: hidden;
    background-color: #12161f;
    background: url(../../img/login_bg.png) no-repeat center;
    background-size: 100% 100%;
    transition: all .3s ease-in-out; 
    box-sizing: border-box;
    padding: 15px;
    padding-top: 0;
    &::before{
      content:'';
      position: absolute;
      left: 15px;
      top: calc(100vh * 25 / 1080);
      width: calc(100vw * 120 / 1920);
      height: calc(100vh * 1011 / 1080);
      background: url(../../img/login_left_bg_1.png) no-repeat center;
      background-size: 100% 100%;
    }
    &::after{
      content:'';
      position: absolute;
      right: 15px;
      top: calc(100vh * 25 / 1080);
      width: calc(100vw * 120 / 1920);
      height: calc(100vh * 1011 / 1080);
      background: url(../../img/login_right_bg_1.png) no-repeat center;
      background-size: 100% 100%;
    }
    .login_top{
      margin-left: calc(100vw * 30 / 1920);;
      width: 100%;
      height: calc(100vh * 95 / 1080);
      display: flex;
      justify-content: space-between;
      .login_top_left{
        margin-top: calc(100vh * 25 / 1080);
        padding-left: calc(100vw * 120 / 1920);
        width: calc(100vw * 420 / 1920);
        height: calc(100vh * 14 / 1080);
        img{
          width: 100%;
          height: auto; 
        }
      }
      .login_top_mid{
        width: calc(100vw * 1035 / 1920);
        height: calc(100vh * 107 / 1080);
        img{
          width: 100%;
          height: auto;
        }
      }
      .login_top_right{
        margin-right: calc(100vw * 50 / 1920);
        margin-top: calc(100vh * 25 / 1080);
        width: calc(100vw * 550 / 1920);
        height: calc(100vh * 40 / 1080);
        display: flex;
        align-items: center;
        img{
          &:first-child{
            
            width: calc(100vw * 420 / 1920);
            height: calc(100vh * 15 / 1080);
          }
          &:nth-child(2){
            width: calc(100vw * 130 / 1920);
            height: calc(100vh * 40 / 1080);
          }
        }
      }

    }
    .login_con{
      width: calc(100vw * 1920 / 1920);
      height: calc(100vh * 985 / 1080);
      display: flex;
      align-items: center;
      justify-content: center;
      .login_kuang{
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100vw * 800 / 1920);
        height: calc(100vh * 580 / 1080);
        background:url(../../img/login_content.png) center no-repeat;
        background-size: contain;
        .shuru{
          padding-top: calc(100vh * 100 / 1080);
          width: calc(100vw * 560 / 1920);
          height: calc(100vh * 350 / 1080);
          .shuru1{
            margin-bottom: calc(100vh * 45 / 1080);
            width: calc(100vw * 560 / 1920);
            height: calc(100vh * 85 / 1080);
            background: url(../../img/shuru1.png) center no-repeat;
            background-size: contain;
            &:nth-child(2){
              margin-bottom: 65px;
              background: url(../../img/shuru2.png) no-repeat center;
              background-size: contain;
            }
            input{
              box-sizing: border-box;
              padding-left: calc(100vw * 105 / 1920);
              width: 100%;
              height: calc(100vh * 85 / 1080);
              border: none;
              background: none;
              outline: none;
              color: rgba($color: #378bb2, $alpha: 0.7);
              font-size: 22px;
              &::-webkit-input-placeholder{
                color: rgba($color: #378bb2, $alpha: 0.7);
              }
            }
          }
          .shuru2{
            width: calc(100vw * 560 / 1920);
            height: calc(100vh * 85 / 1080);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btn{
              width: calc(100vw * 250 / 1920);
              height: calc(100vh * 85 / 1080);
              display: flex;
              align-items: center;
              justify-content: center;
              background: url(../../img/login_button_bg.png) center no-repeat;
              background-size: contain;
              cursor: pointer;
              &:hover{
                background: url(../../img/login_button_bg_a.png) center no-repeat;
                background-size: contain;
              }
              p{
                letter-spacing: 15px;
                font-size: 32px;
                color: #fff;
                font-family: 'RUI';
              }
            }
          }
        }
      }
    }
    
  }
</style>