<template>
  <div class="gengxin" id="app">
    <headerVue ref="back">城市管理</headerVue>
    <div class="container">
      <div class="con_left animate__animated animate__fadeInLeft">
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">生态环境</i></div>
          <div class="con_c__panle up_panel">
            <div class="st_nav">
              <div
                class="nav1"
                :class="daqi == true ? 'active' : ''"
                @click="daqibtn"
              >
                <p>大气污染</p>
              </div>
              <div
                class="nav1"
                :class="mianyuan == true ? 'active' : ''"
                @click="mianyuanbtn"
              >
                <p>面源污染</p>
              </div>
              <div
                class="nav1"
                :class="huangtu == true ? 'active' : ''"
                @click="huangtubtn"
              >
                <p>黄土裸露</p>
              </div>
              <div
                class="nav1"
                :class="zaoyin == true ? 'active' : ''"
                @click="zaoyinbtn"
              >
                <p>噪音污染</p>
              </div>
            </div>

            <div class="navcon">
              <div class="navcon1" v-show="daqi">
                <!-- <div class="list1">
                  <div class="list1_left">
                    <p>56%</p>
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                  </div>
                  <span>AQI</span>
                  <div class="list1_right">
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                    <p>38%</p>
                  </div>
                </div>

                <div class="list1">
                  <div class="list1_left">
                    <p>56%</p>
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                  </div>
                  <span>PM2.5</span>
                  <div class="list1_right">
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                    <p>38%</p>
                  </div>
                </div>

                <div class="list1">
                  <div class="list1_left">
                    <p>56%</p>
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                  </div>
                  <span>PM10</span>
                  <div class="list1_right">
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                    <p>38%</p>
                  </div>
                </div>

                <div class="list1">
                  <div class="list1_left">
                    <p>56%</p>
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                  </div>
                  <span>SO2</span>
                  <div class="list1_right">
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                    <p>38%</p>
                  </div>
                </div>

                <div class="list1">
                  <div class="list1_left">
                    <p>56%</p>
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                  </div>
                  <span>NO2</span>
                  <div class="list1_right">
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                    <p>38%</p>
                  </div>
                </div>

                <div class="list1">
                  <div class="list1_left">
                    <p>56%</p>
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                  </div>
                  <span>CO</span>
                  <div class="list1_right">
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                    <p>38%</p>
                  </div>
                </div>

                <div class="list1">
                  <div class="list1_left">
                    <p>56%</p>
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                  </div>
                  <span>O3</span>
                  <div class="list1_right">
                    <div class="list1c">
                      <div class="jindu"></div>
                    </div>
                    <p>38%</p>
                  </div>
                </div>

                <div class="wenzi">
                  <p>本月</p>
                  <p>上月</p>
                </div> -->
                <div id="listEcharts1"></div> 
                <div id="listEcharts"></div>
                
              </div>
              <div class="con_c__panle case_file navcon2" v-show="mianyuan">
                <div class="case_file_charts">
                  <div id="pie1" style="height: 150%; width: 150%"></div>
                  <div id="pie2" style="height: 150%; width: 150%"></div>
                </div>
              </div>

              <div class="navcon3" v-show="huangtu">
                <div class="navcon3_1">
                  <p>治理黄土裸露板块</p>
                  <span>28<i>块</i></span>
                  <img src="../../img/chengshiguanli/shu.png" alt="" />
                </div>

                <div class="navcon3_1">
                  <p>治理面积</p>
                  <span>39.48<i>万m²</i></span>
                  <img src="../../img/chengshiguanli/shu.png" alt="" />
                </div>
              </div>

              <div class="navcon4" v-show="zaoyin">
                <div class="navcon4_top">
                  <p>一季度</p>
                  <p>二季度</p>
                  <p>三季度</p>
                  <p>四季度</p>
                </div>
                <div class="navcon4_bot">
                  <div class="bot">
                    <div id="bot1" style="height: 75%; width: 100%"></div>
                    <div id="bot1_1" style="height: 75%; width: 100%"></div>
                  </div>

                  <div class="bot">
                    <div id="bot2" style="height: 75%; width: 100%"></div>
                    <div id="bot2_1" style="height: 75%; width: 100%"></div>
                  </div>

                  <div class="bot">
                    <div id="bot3" style="height: 75%; width: 100%"></div>
                    <div id="bot3_1" style="height: 75%; width: 100%"></div>
                  </div>

                  <div class="bot">
                    <div id="bot4" style="height: 75%; width: 100%"></div>
                    <div id="bot4_1" style="height: 75%; width: 100%"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">文明超市</i></div>
          <div class="con_c__panle up_panel">
            <div class="st_nav">
              <div class="nav1" :class="laji ? 'active' : ''" @click="lajibtn">
                <p>垃圾分类</p>
              </div>
              <div
                class="nav1"
                :class="shengji ? 'active' : ''"
                @click="shengjibtn"
              >
                <p>升级改造</p>
              </div>
              <div
                class="nav1"
                :class="zhian ? 'active' : ''"
                @click="zhianbtn"
              >
                <p>治安整治</p>
              </div>
            </div>
            <div class="navcon">
              <div class="wm_navcon1" v-show="laji">
                <div class="bar" id="lajibar"></div>
                <!-- <div class="wm1">
                  <div class="wm1_top">
                    <p>82</p>
                    <span>%</span>
                  </div>
                  <div class="zhu">
                    <div class="jindu"></div>
                  </div>
                  <div class="wenzi">
                    <p>撒桶覆盖</p>
                  </div>
                </div>

                <div class="wm1">
                  <div class="wm1_top">
                    <p>100</p>
                    <span>%</span>
                  </div>
                  <div class="zhu">
                    <div class="jindu"></div>
                  </div>
                  <div class="wenzi">
                    <p>督导覆盖</p>
                  </div>
                </div>

                <div class="wm1">
                  <div class="wm1_top">
                    <p>100</p>
                    <span>%</span>
                  </div>
                  <div class="zhu">
                    <div class="jindu"></div>
                  </div>
                  <div class="wenzi">
                    <p>厨余垃圾</p>
                    <p>收运覆盖</p>
                  </div>
                </div>

                <div class="wm2">
                  <div class="wm1_top">
                    <p>231</p>
                    <i>份</i>
                  </div>
                  <div class="zhu">
                    <div class="jindu"></div>
                  </div>
                  <div class="wenzi">
                    <p>"门前三包"</p>
                    <p>(已发敬告书)</p>
                  </div>
                </div> -->
              </div>
              <div class="wm_navcon2" v-show="shengji">
                <div class="jindu" id="jindu"></div>
                <!-- <div class="shengji1">
                  <div class="shengji1_t">
                    <p>户外屏公益广告更换</p>
                    <span>75<i>%</i></span>
                  </div>
                  <div class="shengji1_b">
                    <div class="jindu" id="jindu"></div>
                  </div>
                </div>

                <div class="shengji1">
                  <div class="shengji1_t">
                    <p>农贸市场升级改造</p>
                    <span>90<i>%</i></span>
                  </div>
                  <div class="shengji1_b">
                    <div class="jindu"></div>
                  </div>
                </div>

                <div class="shengji1">
                  <div class="shengji1_t">
                    <p>厕所革命</p>
                    <span>84<i>%</i></span>
                  </div>
                  <div class="shengji1_b">
                    <div class="jindu"></div>
                  </div>
                </div> -->
              </div>

              <div class="wm_navcon3" v-show="zhian">
                <div class="zhian1">
                  <!-- <div class="zhian1_top">
                    <p>“双随机、一公开”台账进度</p>
                    <span>75<i>%</i></span>
                    
                  </div>
                  <div class="zhian1_bot">
                    <div class="jindu"></div>
                  </div> -->
                  <div class="jindu" id="jindu1"></div>
                </div>

                <div class="zhian2">
                  <div class="zhian2_1">
                    <div class="circle"></div>
                    <div class="cc">
                      <p>5</p>
                      <span>宗</span>
                    </div>
                    <div class="dibu">
                      <i>文明养犬查处</i>
                    </div>
                  </div>

                  <div class="zhian2_1">
                    <div class="circle"></div>
                    <div class="cc">
                      <p>24</p>
                      <span>宗</span>
                    </div>
                    <div class="dibu">
                      <i>文明养犬线索</i>
                    </div>
                  </div>

                  <div class="zhian2_1">
                    <div class="circle"></div>
                    <div class="cc">
                      <p>31</p>
                      <span>宗</span>
                    </div>
                    <div class="dibu">
                      <i>六乱一超政治</i>
                      <i>立案查处</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">执法效能</i></div>
          <div class="con_c__panle up_panel">
            <div class="zf_list">
              <div class="zf_list1">
                <div class="list_left">
                  <p>2019年</p>
                </div>
                <span>立案<i>196</i>宗，收缴罚款<i>244600</i>元</span>
              </div>
              <div class="zf_list1">
                <div class="list_left">
                  <p>2020年</p>
                </div>
                <span>立案<i>196</i>宗，收缴罚款<i>244600</i>元</span>
              </div>

              <div class="zf_list1">
                <div class="list_left">
                  <p>2021年</p>
                </div>
                <span
                  >未改革前（1-8月）执法队在编在岗的执法员有<i>8</i>名，立案<i>80</i>宗，收缴罚款<i>52112.7</i>元；改革后（9月开始）在编在岗执法员<i>18</i>名，立案<i>120</i>宗，收缴罚款<i>109852</i>元。2022年截止7月底，共立案<i>161</i>宗，收缴罚款<i>164414.3</i>元</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="con_center">
        <div class="map_main animate__animated animate__zoomIn" id="map_main">
          <div class="map_box">
            <img
              src="../../images/map/map_bg.png"
              usemap="#Map"
              alt=""
              srcset=""
            />
            <map name="Map" id="Map"></map>
          </div>
          <div class="map_box_tit">
            <div class="tit tit_xiangmi">
              <div class="t">香蜜社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <!-- <span></span> -->
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangmei">
              <div class="t">香梅社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_qiaoxiang">
              <div class="t">侨香社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_nongyuan">
              <div class="t">农园社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_donghai">
              <div class="t">东海社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_zhuyuan">
              <div class="t">竹园社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_zhulin">
              <div class="t">竹林社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangan">
              <div class="t">香安社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangling">
              <div class="t">香岭社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
          </div>
          <div class="map_box_tip">
            <img src="../../images/map/donghai.png" data-name="donghai" />
            <img src="../../images/map/nongyuan.png" data-name="nongyuan" />
            <img src="../../images/map/qiaoxiang.png" data-name="qiaoxiang" />
            <img src="../../images/map/xiangan.png" data-name="xiangan" />
            <img src="../../images/map/xiangling.png" data-name="xiangling" />
            <img src="../../images/map/xiangmei.png" data-name="xiangmei" />
            <img src="../../images/map/zhulin.png" data-name="zhulin" />
            <img src="../../images/map/zhuyuan.png" data-name="zhuyuan" />
            <img src="../../images/map/xiangmi.png" data-name="xiangmi" />
          </div>
        </div>
        <div class="c_circle">
          <div class="c_circle1"></div>
          <div class="c_circle2"></div>
          <div class="c_circle3"></div>
          <div class="c_circle4"></div>
        </div>
      </div>
      <div class="con_right animate__animated animate__fadeInRight">
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">执法宣教</i></div>
          <div class="con_c__panle up_panel">
            <div class="xuanjiao">
              <div class="xuanjiao1">
                <div class="xj_p">
                  <p>对内教育</p>
                </div>
                <span>每月1次政治理论学习、执法知识培训</span>
              </div>
              <div class="xuanjiao2">
                <div class="xj2_top">
                  <p>对外宣传发布新闻情况</p>
                </div>
                <div class="xj2_echart">
                  <div id="zhu1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">综合执法</i></div>
          <div class="con_c__panle up_panel">
            <div id="zhu2"></div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">队伍建设</i></div>
          <div class="con_c__panle up_panel">
            <div class="duiwu">
              <div class="nav">
                <div
                  class="nav1"
                  :class="zhifa ? 'active' : ''"
                  @click="zhifabtn"
                >
                  <p>执法队每日人员动态</p>
                </div>
                <div
                  class="nav1"
                  :class="shirong ? 'active' : ''"
                  @click="shirongbtn"
                >
                  <p>市容巡查员每月考勤表</p>
                </div>
                <div
                  class="nav1"
                  :class="fuzhu ? 'active' : ''"
                  @click="fuzhubtn"
                >
                  <p>队辅助人员季度、年度</p>
                </div>
                <div
                  class="nav1"
                  :class="lianbing ? 'active' : ''"
                  @click="lianbingbtn"
                >
                  <p>每月大练兵</p>
                </div>
              </div>
              <div class="newcon">
                <div class="newcon1" v-show="zhifa">
                  <div id="zhu3"></div>
                </div>
                <div class="newcon2" v-show="shirong">
                  <div id="zhu4"></div>
                </div>

                <div class="newcon3" v-show="fuzhu">
                  <div class="tab">
                    <div class="tab_header">
                      <p>姓名</p>
                      <p>基础分</p>
                      <p>绩效分</p>
                      <p>加减分</p>
                      <p>等次</p>
                    </div>
                    <div class="tab_con">
                      <div class="tab_con1">
                        <p>陈佳佳</p>
                        <p>60</p>
                        <p>25</p>
                        <p>+2</p>
                        <p :class="hao ? 'hao' : ''" v-show="hao">良好</p>
                        <p :class="youxiu ? 'youxiu' : ''" v-show="youxiu">
                          优秀
                        </p>
                        <p :class="hege ? 'hege' : ''" v-show="hege">合格</p>
                      </div>
                      <div class="tab_con1">
                        <p>陈佳佳</p>
                        <p>60</p>
                        <p>25</p>
                        <p>+2</p>
                        <p :class="hao ? 'hao' : ''" v-show="hao">良好</p>
                        <p :class="youxiu ? 'youxiu' : ''" v-show="youxiu">
                          优秀
                        </p>
                        <p :class="hege ? 'hege' : ''" v-show="hege">合格</p>
                      </div>
                      <div class="tab_con1">
                        <p>陈佳佳</p>
                        <p>60</p>
                        <p>25</p>
                        <p>+2</p>
                        <p :class="hao ? 'hao' : ''" v-show="hao">良好</p>
                        <p :class="youxiu ? 'youxiu' : ''" v-show="youxiu">
                          优秀
                        </p>
                        <p :class="hege ? 'hege' : ''" v-show="hege">合格</p>
                      </div>
                      <div class="tab_con1">
                        <p>陈佳佳</p>
                        <p>60</p>
                        <p>25</p>
                        <p>+2</p>
                        <p :class="hao ? 'hao' : ''" v-show="hao">良好</p>
                        <p :class="youxiu ? 'youxiu' : ''" v-show="youxiu">
                          优秀
                        </p>
                        <p :class="hege ? 'hege' : ''" v-show="hege">合格</p>
                      </div>
                      <div class="tab_con1">
                        <p>陈佳佳</p>
                        <p>60</p>
                        <p>25</p>
                        <p>+2</p>
                        <p :class="hao ? 'hao' : ''" v-show="hao">良好</p>
                        <p :class="youxiu ? 'youxiu' : ''" v-show="youxiu">
                          优秀
                        </p>
                        <p :class="hege ? 'hege' : ''" v-show="hege">合格</p>
                      </div>
                      <div class="tab_con1">
                        <p>陈佳佳</p>
                        <p>60</p>
                        <p>25</p>
                        <p>+2</p>
                        <p :class="hao ? 'hao' : ''" v-show="hao">良好</p>
                        <p :class="youxiu ? 'youxiu' : ''" v-show="youxiu">
                          优秀
                        </p>
                        <p :class="hege ? 'hege' : ''" v-show="hege">合格</p>
                      </div>
                      <div class="tab_con1">
                        <p>陈佳佳</p>
                        <p>60</p>
                        <p>25</p>
                        <p>+2</p>
                        <p :class="hao ? 'hao' : ''" v-show="hao">良好</p>
                        <p :class="youxiu ? 'youxiu' : ''" v-show="youxiu">
                          优秀
                        </p>
                        <p :class="hege ? 'hege' : ''" v-show="hege">合格</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="newcon2 newcon4" v-show="lianbing">
                  <div id="zhu5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerVue></footerVue>
    <div class="bot_line"></div>
  </div>
</template>

<script>
import "../../js/map.js";
import "../../js/num.js";
import $ from "jquery";
const echarts = require("echarts");
import footerVue from "../../components/footerBar.vue";
import headerVue from "../../components/top.vue";
export default {
  name: "chengshiguanli",
  components: {
    headerVue,
    footerVue,
  },
  data() {
    return {
      youxiu: true,
      hao: false,
      hege: false,
      daqi: true,
      mianyuan: false,
      huangtu: false,
      zaoyin: false,
      laji: true,
      shengji: false,
      zhian: false,
      zhifa: true,
      shirong: false,
      fuzhu: false,
      lianbing: false,
      data1: {
        value: 36.2,
        label: "排查覆盖率",
        color: "#38c0ff",
        max: 100,
        u: "%",
      },
      data2: {
        value: 78.8,
        label: "政治合格率",
        color: "#1de1a3",
        max: 100,
        u: "%",
      },

      data3: {
        value: 61,
        label: "白天",
        color: "#38c0ff",
        max: 100,
        u: "dB",
      },
      data3_1: {
        value: 36,
        label: "晚间",
        color: "#38c0ff",
        max: 100,
        u: "dB",
      },

      data4: {
        value: 36,
        label: "白天",
        color: "#ffa838",
        max: 100,
        u: "dB",
      },
      data4_1: {
        value: 18,
        label: "晚间",
        color: "#ffa838",
        max: 100,
        u: "dB",
      },

      data5: {
        value: 82,
        label: "白天",
        color: "#1de1a3",
        max: 100,
        u: "dB",
      },
      data5_1: {
        value: 36,
        label: "晚间",
        color: "#1de1a3",
        max: 100,
        u: "dB",
      },

      data6: {
        value: 74,
        label: "白天",
        color: "#ff4a4a",
        max: 100,
        u: "dB",
      },
      data6_1: {
        value: 8,
        label: "晚间",
        color: "#ff4a4a",
        max: 100,
        u: "dB",
      },

      barData: {
        name: [
          "撤桶覆盖",
          "督导覆盖",
          "厨余垃圾\n收运覆盖",
          '"门前三包"\n(已发敬告书)',
        ],
        value: ["82%", "82%", "100%", "231份"],
      },

      barData1: {
        name: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月"],
        value: ["14", "15", "16", "28", "4", "6", "7", "2"],
      },

      barData2: {
        name: ["六乱一超", "文明养犬", "户外广告", "园林绿化", "垃圾分类"],
        value: ["36", "24", "34", "33", "35"],
        value1: ["4", "7", "8", "5", "4"],
      },

      barData3: {
        name: ["综合室", "一中队", "二中队", "三中队", "城建办"],
        value: ["36", "24", "34", "33", "35"],
        value1: ["4", "7", "8", "5", "4"],
      },

      barData4: {
        name: [
          "王艳伟",
          "王建国",
          "扬程",
          "谈来劲",
          "杨瑞",
          "胡淑华",
          "王大斌",
        ],
        value: ["36", "24", "34", "33", "35", "35", "35"],
      },

      barData5: {
        name: [
          "王艳伟",
          "王建国",
          "扬程",
          "谈来劲",
          "杨瑞",
          "胡淑华",
          "王大斌",
        ],
        value: ["36", "24", "34", "33", "35", "35", "35"],
      },

      listData: {
        name: ["AQI", "PM2.5", "PM10", "SO2", "NO2", "CO", "O3"],
        value: ["36", "24", "34", "33", "35", "35", "35"],
        value1: ["52", "42", "32", "12", "62", "82", "62"],
      },
    };
  },
  created() {
    this.getFunction1();
  },
  mounted() {
    this.getecharts2(this.data1, "pie1");
    this.getecharts2(this.data2, "pie2");
    this.getmap();
    this.getnum();
    this.getFunction1();
    this.getecharts3(this.data3, "bot1");
    this.getecharts3(this.data3_1, "bot1_1");

    this.getecharts3(this.data4, "bot2");
    this.getecharts3(this.data4_1, "bot2_1");

    this.getecharts3(this.data5, "bot3");
    this.getecharts3(this.data5_1, "bot3_1");

    this.getecharts3(this.data6, "bot4");
    this.getecharts3(this.data6_1, "bot4_1");

    this.getListE(this.listData, "listEcharts");
    this.getListE1(this.listData, "listEcharts1");

    this.getjindu();
    this.getjindu1();
    this.getbar(this.barData, "lajibar");
    this.getbar1(this.barData1, "zhu1");
    this.getbar2(this.barData2, "zhu2");
    this.getbar3(this.barData3, "zhu3");
    this.getbar4(this.barData4, "zhu4");
    this.getbar5(this.barData5, "zhu5");
  },
  methods: {
    fontSize(res) {
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      return (res * clientWidth) / 1920;
    },

    daqibtn() {
      this.daqi = true;
      this.mianyuan = false;
      this.huangtu = false;
      this.zaoyin = false;
    },
    mianyuanbtn() {
      this.daqi = false;
      this.mianyuan = true;
      this.huangtu = false;
      this.zaoyin = false;
    },
    huangtubtn() {
      this.daqi = false;
      this.mianyuan = false;
      this.huangtu = true;
      this.zaoyin = false;
    },
    zaoyinbtn() {
      this.daqi = false;
      this.mianyuan = false;
      this.huangtu = false;
      this.zaoyin = true;
    },
    lajibtn() {
      this.laji = true;
      this.shengji = false;
      this.zhian = false;
    },
    shengjibtn() {
      this.laji = false;
      this.shengji = true;
      this.zhian = false;
    },
    zhianbtn() {
      this.laji = false;
      this.shengji = false;
      this.zhian = true;
    },

    zhifabtn() {
      this.zhifa = true;
      this.shirong = false;
      this.fuzhu = false;
      this.lianbing = false;
    },
    shirongbtn() {
      this.zhifa = false;
      this.shirong = true;
      this.fuzhu = false;
      this.lianbing = false;
    },
    fuzhubtn() {
      this.zhifa = false;
      this.shirong = false;
      this.fuzhu = true;
      this.lianbing = false;
    },

    lianbingbtn() {
      this.zhifa = false;
      this.shirong = false;
      this.fuzhu = false;
      this.lianbing = true;
    },

    getecharts2(data, id) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        series: [
          {
            name: data.label,
            type: "gauge",
            z: 1,
            min: 0,
            max: 100,
            splitNumber: 10,
            radius: "80%",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                width: 1,
                color: [[1, "#4f5259"]],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
            },
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
            title: {
              color: "#ffffff",
              offsetCenter: [0, "60%"],
            },
            data: [
              {
                value: 0,
                name: data.u,
              },
            ],
          },
          {
            name: data.label,
            type: "gauge",
            z: 1,
            min: 0,
            max: 100,
            radius: "50%",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                width: 1,
                color: [[1, "#4f5259"]],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
              length: 15,
            },
            detail: {
              show: false,
            },
            data: [],
          },
          {
            name: data.label,
            type: "gauge",
            z: 2,
            min: 0,
            max: 100,
            radius: "70%",
            axisLine: {
              // 坐标轴线
              show: true,
              lineStyle: {
                width: this.fontSize(10),
                color: [
                  [data.value / data.max, data.color],
                  [1, "#4f5359"],
                ],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
            },
            title: {
              color: "rgba(255,255,255,.7)",
              fontSize: this.fontSize(16),
              offsetCenter: [0, "130%"],
              width: 120,
              overflow: "break",
              lineHeight: 16,
            },
            detail: {
              offsetCenter: [0, "0%"],
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "#FFFFFF",
                fontSize: this.fontSize(26),
                fontFamily: "RUI",
              },
            },
            pointer: {
              show: false,
            },
            data: [
              {
                value: data.value,
                name: data.label,
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },

    getecharts3(data, id) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        series: [
          {
            name: data.label,
            type: "gauge",
            z: 1,
            min: 0,
            max: 100,
            splitNumber: 10,
            radius: "80%",
            axisLine: {
              show: false,
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                width: 1,
                color: [[1, "#4f5259"]],
              },
            },

            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
            },
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
            title: {
              color: "#ffffff",
              offsetCenter: ["30%", "0%"],
              fontSize: this.fontSize(12),
            },
            data: [
              {
                value: 0,
                name: data.u,
              },
            ],
          },
          {
            name: data.label,
            type: "gauge",
            z: 1,
            min: 0,
            max: 100,
            radius: "100%",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                width: 1,
                color: [[1, "#4f5259"]],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
              length: 15,
            },
            detail: {
              show: false,
            },
            data: [],
          },
          {
            name: data.label,
            type: "gauge",
            z: 2,
            min: 0,
            max: 100,
            radius: "100%",
            axisLine: {
              // 坐标轴线
              show: true,
              lineStyle: {
                width: this.fontSize(10),
                color: [
                  [data.value / data.max, data.color],
                  [1, "#4f5359"],
                ],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
            },
            title: {
              color: "rgba(255,255,255,.7)",
              fontSize: this.fontSize(12),
              offsetCenter: [0, "70%"],
              width: 120,
              overflow: "break",
              lineHeight: 16,
            },
            detail: {
              offsetCenter: ["-20%", "0%"],
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "#FFFFFF",
                fontSize: this.fontSize(14),
                // fontFamily: "RUI",
              },
            },
            pointer: {
              show: false,
            },
            data: [
              {
                value: data.value,
                name: data.label,
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },

    getbar(data, id) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(0,0,0,.8)",
          borderColor: "rgba(0,0,0,.8)",
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(12),
          },
        },
        grid: {
          top: "10%",
          right: "8%",
          left: "3%",
          bottom: "1%",
          containLabel: true,
        },
        xAxis: [
          {
            // name: '月',
            // nameTextStyle: {
            //   fontSize: this.fontSize(12),
            //   color: 'rgba(255,255,255,.5)'
            // },
            // nameGap: this.fontSize(3),
            type: "category",
            data: data.name,
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0, // 使x轴文字显示全
              // rotate: 35,
              color: "rgba(255,255,255,.5)",
              textStyle: {
                fontSize: 12,
              },
            },
          },
        ],
        yAxis: [
          {
            // name: '数量',
            // nameTextStyle: {
            //   fontSize: 12,
            //   color: 'rgba(255,255,255,.5)'
            // },
            nameGap: 12,
            axisLabel: {
              show: false,
              formatter: "{value}",
              color: "rgba(255,255,255,.5)",
              margin: 12,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              lineStyle: {
                color: "#38c0ff",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        series: [
          {
            // name:"立案调查",
            // stack:"规土案件",
            type: "bar",
            data: [22, 31, 36, 23],
            barWidth: 20,
            label: {
              //图形上的文本标签
              show: true,
              position: "top", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
            itemStyle: {
              // color: "#38c0ff",
              decal: {
                show: true,
                symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                symbolKeepAspect: true, //是否保持图案的长宽比
                color: "#383c44",
                backgroundColor: "",
                dashArrayX: 400,
                dashArrayY: 2,
                rotation: 0, //图案的整体旋转角度（弧度制）
                maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                maxTileHeight: 512,
              },
              color: function (params) {
                let num = myColor.length; //得到myColor颜色数组的长度
                return myColor[params.dataIndex % num]; //返回颜色数组中的一个对应的颜色值
              },
            },
          },
        ],
        //     aria:{
        //     enabled:true,
        //     decal:{
        //         show: true,

        //         decals: {
        //             // symbol: 'react',
        //             symbol: 'rect',  //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
        //                 symbolSize: 1,  //表示占图案区域的百分比，取值范围：0-1
        //                 symbolKeepAspect: true,  //是否保持图案的长宽比
        //                 color: '#3bb0e6',
        //                 backgroundColor: '',
        //                 dashArrayX: 125,
        //                 dashArrayY: 2,
        //                 rotation: 0,  //图案的整体旋转角度（弧度制）
        //                 maxTileWidth: 512,  //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
        //                 maxTileHeight: 512
        //         }
        //     }
        // }
      };
      let myColor = ["#4fc0fb", "#ffa838", "#1de1a3", "#ff6464", "#8B78F6"];
      myChart.setOption(option);
    },

    getbar1(data, id) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(0,0,0,.8)",
          borderColor: "rgba(0,0,0,.8)",
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(12),
          },
        },
        grid: {
          top: "25%",
          right: "3%",
          left: "3%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            // name: '月',
            // nameTextStyle: {
            //   fontSize: this.fontSize(12),
            //   color: 'rgba(255,255,255,.5)'
            // },
            // nameGap: this.fontSize(3),
            type: "category",
            data: data.name,
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0, // 使x轴文字显示全
              // rotate: 35,
              color: "rgba(255,255,255,.5)",
              textStyle: {
                fontSize: 12,
              },
            },
          },
        ],
        yAxis: [
          {
            // name: '数量',
            // nameTextStyle: {
            //   fontSize: 12,
            //   color: 'rgba(255,255,255,.5)'
            // },
            nameGap: 12,
            axisLabel: {
              show: false,
              formatter: "{value}",
              color: "rgba(255,255,255,.5)",
              margin: 12,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              lineStyle: {
                color: "#38c0ff",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        series: [
          {
            // name:"立案调查",
            // stack:"规土案件",
            type: "bar",
            data: data.value,
            barWidth: 20,
            label: {
              //图形上的文本标签
              show: true,
              position: "top", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
            itemStyle: {
              color: "#ffb454",
              decal: {
                show: true,
                symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                symbolKeepAspect: true, //是否保持图案的长宽比
                color: "#ea9f3f",
                backgroundColor: "",
                dashArrayX: 400,
                dashArrayY: 2,
                rotation: 45, //图案的整体旋转角度（弧度制）
                maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                maxTileHeight: 512,
              },
              //     color: function(params) {
              // 				let num = myColor.length; //得到myColor颜色数组的长度
              // 				return myColor[params.dataIndex % num]; //返回颜色数组中的一个对应的颜色值
              // 			},
            },
          },
        ],
      };
      // let myColor = ['#4fc0fb', '#ffa838', '#1de1a3', '#ff6464', '#8B78F6']
      myChart.setOption(option);
    },

    getbar2(data, id) {
      var myChart1 = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(0,0,0,.8)",
          borderColor: "rgba(0,0,0,.8)",
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(12),
          },
        },
        legend: {
          show: true,
          top: "3%",
          right: "8%",
          itemWidth: this.fontSize(8),
          itemHeight: this.fontSize(8),
          textStyle: {
            fontSize: this.fontSize(12),
            color: "rgba(255,255,255,.5)",
          },
          icon: "rect",
          itemGap: this.fontSize(18),
        },
        grid: {
          top: "15%",
          right: "3%",
          left: "3%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: [
          {
            // name: '月',
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(3),
            type: "category",
            data: data.name,
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
              textStyle: {
                fontSize: this.fontSize(12),
              },
            },
          },
        ],
        yAxis: [
          {
            // name: '数量',
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(12),
            axisLabel: {
              show: false,
              formatter: "{value}",
              color: "rgba(255,255,255,.5)",
              margin: this.fontSize(12),
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              lineStyle: {
                color: "#38c0ff",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        series: [
          {
            name: "立案调查",
            // stack:"规土案件",
            type: "bar",
            data: data.value,
            barWidth: this.fontSize(20),
            itemStyle: {
              color: "#ff6464",
              decal: {
                show: true,
                symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                symbolKeepAspect: true, //是否保持图案的长宽比
                color: "#ed5252",
                backgroundColor: "",
                dashArrayX: 400,
                dashArrayY: 2,
                rotation: 45, //图案的整体旋转角度（弧度制）
                maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                maxTileHeight: 512,
              },
            },
            label: {
              show: true,
              position: "top", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
          },
          {
            name: "已结案",
            // stack:"规土案件",
            type: "bar",
            data: data.value1,
            barWidth: this.fontSize(20),
            itemStyle: {
              color: "#3ce4b0",
              decal: {
                show: true,
                symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                symbolKeepAspect: true, //是否保持图案的长宽比
                color: "#2ad29d",
                backgroundColor: "",
                dashArrayX: 400,
                dashArrayY: 2,
                rotation: 45, //图案的整体旋转角度（弧度制）
                maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                maxTileHeight: 512,
              },
            },
            label: {
              show: true,
              position: "top", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
          },
        ],
      };

      myChart1.setOption(option);
    },

    getbar3(data, id) {
      var myChart1 = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(0,0,0,.8)",
          borderColor: "rgba(0,0,0,.8)",
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(12),
          },
        },
        legend: {
          show: true,
          top: "1%",
          right: "8%",
          itemWidth: this.fontSize(8),
          itemHeight: this.fontSize(8),
          textStyle: {
            fontSize: this.fontSize(12),
            color: "rgba(255,255,255,.5)",
          },
          icon: "rect",
          itemGap: this.fontSize(18),
        },
        grid: {
          top: "25%",
          right: "3%",
          left: "3%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            // name: '月',
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(3),
            type: "category",
            data: data.name,
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
              textStyle: {
                fontSize: this.fontSize(12),
              },
            },
          },
        ],
        yAxis: [
          {
            // name: '数量',
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(12),
            axisLabel: {
              show: false,
              formatter: "{value}",
              color: "rgba(255,255,255,.5)",
              margin: this.fontSize(12),
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              lineStyle: {
                color: "#38c0ff",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        dataZoom: [
          {
            type: "slider",
            realtime: true,
            start: 0,
            end: 50, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
            height: 2, //组件高度
            left: 5, //左边的距离
            right: 5, //右边的距离
            bottom: -5, //下边的距离
            show: true, // 是否展示
            fillerColor: "rgba(17, 100, 210, 0.42)", // 滚动条颜色
            borderColor: "rgba(17, 100, 210, 0.12)",
            handleSize: 0, //两边手柄尺寸
            showDetail: false, //拖拽时是否展示滚动条两侧的文字
            zoomLock: true, //是否只平移不缩放
            moveOnMouseMove: false, //鼠标移动能触发数据窗口平移
            //zoomOnMouseWheel: false, //鼠标移动能触发数据窗口缩放

            //下面是自己发现的一个问题，当点击滚动条横向拖拽拉长滚动条时，会出现文字重叠，导致效果很不好，以此用下面四个属性进行设置，当拖拽时，始终保持显示六个柱状图，可结合自己情况进行设置。添加这个属性前后的对比见**图二**
            startValue: 0, // 从头开始。
            endValue: 6, // 最多六个
            minValueSpan: 6, // 放大到最少几个
            maxValueSpan: 6, //  缩小到最多几个
          },
          {
            type: "inside", // 支持内部鼠标滚动平移
            start: 0,
            end: 50,
            zoomOnMouseWheel: false, // 关闭滚轮缩放
            moveOnMouseWheel: true, // 开启滚轮平移
            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
          },
        ],
        series: [
          {
            name: "应到",
            // stack:"规土案件",
            type: "bar",
            data: data.value,
            barWidth: this.fontSize(20),
            itemStyle: {
              color: "#54c9ff",
              decal: {
                show: true,
                symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                symbolKeepAspect: true, //是否保持图案的长宽比
                color: "#3db2e8",
                backgroundColor: "",
                dashArrayX: 400,
                dashArrayY: 2,
                rotation: 45, //图案的整体旋转角度（弧度制）
                maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                maxTileHeight: 512,
              },
            },
            label: {
              show: true,
              position: "top", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
          },
          {
            name: "实到",
            // stack:"规土案件",
            type: "bar",
            data: data.value1,
            barWidth: this.fontSize(20),
            itemStyle: {
              color: "#3de5b0",
              decal: {
                show: true,
                symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                symbolKeepAspect: true, //是否保持图案的长宽比
                color: "#2cd49e",
                backgroundColor: "",
                dashArrayX: 400,
                dashArrayY: 2,
                rotation: 45, //图案的整体旋转角度（弧度制）
                maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                maxTileHeight: 512,
              },
            },
            label: {
              show: true,
              position: "top", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
          },
        ],
      };

      myChart1.setOption(option);
    },

    getbar4(data, id) {
      var myChart1 = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(0,0,0,.8)",
          borderColor: "rgba(0,0,0,.8)",
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(12),
          },
        },
        legend: {
          show: true,
          top: "1%",
          right: "8%",
          itemWidth: this.fontSize(8),
          itemHeight: this.fontSize(8),
          textStyle: {
            fontSize: this.fontSize(12),
            color: "rgba(255,255,255,.5)",
          },
          icon: "rect",
          itemGap: this.fontSize(18),
        },
        grid: {
          top: "25%",
          right: "3%",
          left: "3%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            // name: '月',
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(3),
            type: "category",
            data: data.name,
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
              textStyle: {
                fontSize: this.fontSize(12),
              },
            },
          },
        ],
        yAxis: [
          {
            // name: '数量',
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(12),
            axisLabel: {
              show: false,
              formatter: "{value}",
              color: "rgba(255,255,255,.5)",
              margin: this.fontSize(12),
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              lineStyle: {
                color: "#38c0ff",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        dataZoom: [
          {
            type: "slider",
            realtime: true,
            start: 0,
            end: 50, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
            height: 2, //组件高度
            left: 5, //左边的距离
            right: 5, //右边的距离
            bottom: -5, //下边的距离
            show: true, // 是否展示
            fillerColor: "rgba(17, 100, 210, 0.42)", // 滚动条颜色
            borderColor: "rgba(17, 100, 210, 0.12)",
            handleSize: 0, //两边手柄尺寸
            showDetail: false, //拖拽时是否展示滚动条两侧的文字
            zoomLock: true, //是否只平移不缩放
            moveOnMouseMove: false, //鼠标移动能触发数据窗口平移
            //zoomOnMouseWheel: false, //鼠标移动能触发数据窗口缩放

            //下面是自己发现的一个问题，当点击滚动条横向拖拽拉长滚动条时，会出现文字重叠，导致效果很不好，以此用下面四个属性进行设置，当拖拽时，始终保持显示六个柱状图，可结合自己情况进行设置。添加这个属性前后的对比见**图二**
            startValue: 0, // 从头开始。
            endValue: 6, // 最多六个
            minValueSpan: 6, // 放大到最少几个
            maxValueSpan: 6, //  缩小到最多几个
          },
          {
            type: "inside", // 支持内部鼠标滚动平移
            start: 0,
            end: 50,
            zoomOnMouseWheel: false, // 关闭滚轮缩放
            moveOnMouseWheel: true, // 开启滚轮平移
            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
          },
        ],
        series: [
          {
            // name:"应到",
            // stack:"规土案件",
            type: "bar",
            data: data.value,
            barWidth: this.fontSize(20),
            itemStyle: {
              color: "#54c9ff",
              decal: {
                show: true,
                symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                symbolKeepAspect: true, //是否保持图案的长宽比
                color: "#3db2e8",
                backgroundColor: "",
                dashArrayX: 400,
                dashArrayY: 2,
                rotation: 45, //图案的整体旋转角度（弧度制）
                maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                maxTileHeight: 512,
              },
            },
            label: {
              show: true,
              position: "top", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
          },
        ],
      };

      myChart1.setOption(option);
    },

    getbar5(data, id) {
      var myChart1 = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(0,0,0,.8)",
          borderColor: "rgba(0,0,0,.8)",
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(12),
          },
        },
        legend: {
          show: true,
          top: "1%",
          right: "8%",
          itemWidth: this.fontSize(8),
          itemHeight: this.fontSize(8),
          textStyle: {
            fontSize: this.fontSize(12),
            color: "rgba(255,255,255,.5)",
          },
          icon: "rect",
          itemGap: this.fontSize(18),
        },
        grid: {
          top: "25%",
          right: "3%",
          left: "3%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            // name: '月',
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(3),
            type: "category",
            data: data.name,
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
              textStyle: {
                fontSize: this.fontSize(12),
              },
            },
          },
        ],
        yAxis: [
          {
            // name: '数量',
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(12),
            axisLabel: {
              show: false,
              formatter: "{value}",
              color: "rgba(255,255,255,.5)",
              margin: this.fontSize(12),
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              lineStyle: {
                color: "#38c0ff",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],

        dataZoom: [
          {
            type: "slider",
            realtime: true,
            start: 0,
            end: 50, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
            height: 2, //组件高度
            left: 5, //左边的距离
            right: 5, //右边的距离
            bottom: -5, //下边的距离
            show: true, // 是否展示
            fillerColor: "rgba(17, 100, 210, 0.42)", // 滚动条颜色
            borderColor: "rgba(17, 100, 210, 0.12)",
            handleSize: 0, //两边手柄尺寸
            showDetail: false, //拖拽时是否展示滚动条两侧的文字
            zoomLock: true, //是否只平移不缩放
            moveOnMouseMove: false, //鼠标移动能触发数据窗口平移
            //zoomOnMouseWheel: false, //鼠标移动能触发数据窗口缩放

            //下面是自己发现的一个问题，当点击滚动条横向拖拽拉长滚动条时，会出现文字重叠，导致效果很不好，以此用下面四个属性进行设置，当拖拽时，始终保持显示六个柱状图，可结合自己情况进行设置。添加这个属性前后的对比见**图二**
            startValue: 0, // 从头开始。
            endValue: 6, // 最多六个
            minValueSpan: 6, // 放大到最少几个
            maxValueSpan: 6, //  缩小到最多几个
          },
          {
            type: "inside", // 支持内部鼠标滚动平移
            start: 0,
            end: 50,
            zoomOnMouseWheel: false, // 关闭滚轮缩放
            moveOnMouseWheel: true, // 开启滚轮平移
            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
          },
        ],
        series: [
          {
            // name:"应到",
            // stack:"规土案件",
            type: "bar",
            data: data.value,
            barWidth: this.fontSize(20),
            itemStyle: {
              color: "#3de5b1",
              decal: {
                show: true,
                symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                symbolKeepAspect: true, //是否保持图案的长宽比
                color: "#29d19c",
                backgroundColor: "",
                dashArrayX: 400,
                dashArrayY: 2,
                rotation: 45, //图案的整体旋转角度（弧度制）
                maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                maxTileHeight: 512,
              },
            },
            label: {
              show: true,
              position: "top", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
          },
        ],
      };

      myChart1.setOption(option);
    },

    getListE(data, id) {
      var myChart1 = echarts.init(document.getElementById(id));
      var option = {
					grid: { // 直角坐标系内绘图网格
						left: '10', //grid 组件离容器左侧的距离,
						//left的值可以是80这样具体像素值，
						//也可以是'80%'这样相对于容器高度的百分比
						top: '0',
						right: '10',
						bottom: '0',
						containLabel: true //gid区域是否包含坐标轴的刻度标签。为true的时候，
						// left/right/top/bottom/width/height决定的是包括了坐标轴标签在内的
						//所有内容所形成的矩形的位置.常用于【防止标签溢出】的场景
					},
					xAxis: { //直角坐标系grid中的x轴,
						//一般情况下单个grid组件最多只能放上下两个x轴,
						//多于两个x轴需要通过配置offset属性防止同个位置多个x轴的重叠。
						type: 'value', //坐标轴类型,分别有：
						//'value'-数值轴；'category'-类目轴;
						//'time'-时间轴;'log'-对数轴
						splitLine: {
							show: false
						}, //坐标轴在 grid 区域中的分隔线
						axisLabel: {
							show: false
						}, //坐标轴刻度标签
						axisTick: {
							show: false
						}, //坐标轴刻度
						axisLine: {
							show: false
						}, //坐标轴轴线
					},
					yAxis: {
						type: 'category',
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: "#acadb1",
								align: "right",
                fontSize:"12"
							},
						},
						data: data.name //类目数据，在类目轴（type: 'category'）中有效。
						//如果没有设置 type，但是设置了axis.data,则认为type 是 'category'。
					},
					series: [ //系列列表。每个系列通过 type 决定自己的图表类型
						{
							name: '%', //系列名称
							type: 'bar', //柱状、条形图
							barWidth: 8, //柱条的宽度,默认自适应
							data: data.value, //系列中数据内容数组
							label: { //图形上的文本标签
								show: true,
								position: 'right', //标签的位置
								// offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
								formatter: '{c}', //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
								color: '#fff', //标签字体颜色
								fontSize: 12 //标签字号
							},
							itemStyle: { //图形样式
								normal: { //normal 图形在默认状态下的样式;
									//emphasis图形在高亮状态下的样式
									barBorderRadius: 2, //柱条圆角半径,单位px.
									//此处统一设置4个角的圆角大小;
									//也可以分开设置[10,10,10,10]顺时针左上、右上、右下、左下
									color: {
										x: 0,
										y: 1,
										x2: 0,
										y2: 0,
										colorStops: [{
											offset: 0,
											color: '#fed032' // 0% 处的颜色
										}, {
											offset: 1,
											color: '#ffa800' // 100% 处的颜色
										}],
										global: false // 缺省为 false
									},
                  decal: {
                  show: true,
                  symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                  symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                  symbolKeepAspect: true, //是否保持图案的长宽比
                  color: "#e99f3e",
                  backgroundColor: "",
                  dashArrayX: 400,
                  dashArrayY: 2,
                  rotation: 45, //图案的整体旋转角度（弧度制）
                  maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                  maxTileHeight: 512,
                },

									// color: function(params) {
									// 	let num = myColor.length; //得到myColor颜色数组的长度
									// 	return myColor[params.dataIndex % num]; //返回颜色数组中的一个对应的颜色值
									// },
								}
							},
							zlevel: 1 //柱状图所有图形的 zlevel 值,
							//zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
						},
						{
							name: '进度条背景',
							type: 'bar',
							label: { //图形上的文本标签
								show: false,
								position: 'right', //标签的位置
								offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
								// formatter: '{c}{a}',//标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
								color: '#fff', //标签字体颜色
								fontSize: 12 //标签字号
							},
							barGap: '-100%', //不同系列的柱间距离，为百分比。
							// 在同一坐标系上，此属性会被多个 'bar' 系列共享。
							// 此属性应设置于此坐标系中最后一个 'bar' 系列上才会生效，
							//并且是对此坐标系中所有 'bar' 系列生效。
							barWidth: 8,
							data: ['100', '100', '100', '100', '100','100','100'],
							color: '#02325f', //柱条颜色
							itemStyle: {
								normal: {
									barBorderRadius: 2
								}
							}
						},

					]
				}

      myChart1.setOption(option);
    },
    getListE1(data, id) {
      var myChart1 = echarts.init(document.getElementById(id));
      var option = {
					grid: { // 直角坐标系内绘图网格
						left: '10', //grid 组件离容器左侧的距离,
						//left的值可以是80这样具体像素值，
						//也可以是'80%'这样相对于容器高度的百分比
						top: '0',
						right: '10',
						bottom: '0',
						containLabel: true //gid区域是否包含坐标轴的刻度标签。为true的时候，
						// left/right/top/bottom/width/height决定的是包括了坐标轴标签在内的
						//所有内容所形成的矩形的位置.常用于【防止标签溢出】的场景
					},
					xAxis: { //直角坐标系grid中的x轴,
						//一般情况下单个grid组件最多只能放上下两个x轴,
						//多于两个x轴需要通过配置offset属性防止同个位置多个x轴的重叠。
						type: 'value', //坐标轴类型,分别有：
            inverse:true,
						//'value'-数值轴；'category'-类目轴;
						//'time'-时间轴;'log'-对数轴
						splitLine: {
							show: false
						}, //坐标轴在 grid 区域中的分隔线
						axisLabel: {
							show: false
						}, //坐标轴刻度标签
						axisTick: {
							show: false
						}, //坐标轴刻度
						axisLine: {
							show: false
						}, //坐标轴轴线
					},
					yAxis: {
						type: 'category',
            inverse:true,
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisLabel: {
							show: false,
							textStyle: {
								color: "#acadb1",
								align: "right",
                fontSize:"12"
							},
						},
						data: data.name //类目数据，在类目轴（type: 'category'）中有效。
						//如果没有设置 type，但是设置了axis.data,则认为type 是 'category'。
					},
					series: [ //系列列表。每个系列通过 type 决定自己的图表类型
						{
							name: '%', //系列名称
							type: 'bar', //柱状、条形图
							barWidth: 8, //柱条的宽度,默认自适应
							data: data.value1, //系列中数据内容数组
							label: { //图形上的文本标签
								show: true,
								position: 'left', //标签的位置
								// offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
								formatter: '{c}', //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
								color: '#fff', //标签字体颜色
								fontSize: 12 //标签字号
							},
							itemStyle: { //图形样式
                position: 'right',
								normal: { //normal 图形在默认状态下的样式;
									//emphasis图形在高亮状态下的样式
									barBorderRadius: 2, //柱条圆角半径,单位px.
									//此处统一设置4个角的圆角大小;
									//也可以分开设置[10,10,10,10]顺时针左上、右上、右下、左下
									color: {
										x: 0,
										y: 1,
										x2: 0,
										y2: 0,
										colorStops: [{
											offset: 0,
											color: '#52c7fc' // 0% 处的颜色
										}, {
											offset: 1,
											color: '#52c7fc' // 100% 处的颜色
										}],
										global: false // 缺省为 false
									},
                  decal: {
                  show: true,
                  symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                  symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                  symbolKeepAspect: true, //是否保持图案的长宽比
                  color: "#40b5ea",
                  backgroundColor: "",
                  dashArrayX: 400,
                  dashArrayY: 2,
                  rotation: 45, //图案的整体旋转角度（弧度制）
                  maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                  maxTileHeight: 512,
                },

									// color: function(params) {
									// 	let num = myColor.length; //得到myColor颜色数组的长度
									// 	return myColor[params.dataIndex % num]; //返回颜色数组中的一个对应的颜色值
									// },
								}
							},
							zlevel: 1 //柱状图所有图形的 zlevel 值,
							//zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
						},
						{
							name: '进度条背景',
							type: 'bar',
							label: { //图形上的文本标签
								show: false,
								position: 'right', //标签的位置
								offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
								// formatter: '{c}{a}',//标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
								color: '#fff', //标签字体颜色
								fontSize: 12 //标签字号
							},
							barGap: '-100%', //不同系列的柱间距离，为百分比。
							// 在同一坐标系上，此属性会被多个 'bar' 系列共享。
							// 此属性应设置于此坐标系中最后一个 'bar' 系列上才会生效，
							//并且是对此坐标系中所有 'bar' 系列生效。
							barWidth: 8,
							data: ['100', '100', '100', '100', '100','100','100'],
							color: '#02325f', //柱条颜色
							itemStyle: {
								normal: {
									barBorderRadius: 2
								}
							}
						},

					]
				}

      myChart1.setOption(option);
    },

    getFunction1() {
      console.log(12312);
      $(".list1_left .jindu").animate({ width: "60%" }, 650);
      $(".list1_right .jindu").animate({ width: "90%" }, 650);
      $(".zhu .jindu").animate({ height: "60%" }, 650);
      $(".topleft .jindu").animate({ height: "60%" }, 650);
      $(".topright .jindu").animate({ height: "30%" }, 650);
      $(".duiwu1_bg .jindu").animate({ height: "70%" }, 650);
      $(".duiwu1_bg .jindu1").animate({ height: "30%" }, 650);
      $(".shengji1_b .jindu").animate({ width: "60%" }, 650);
      $(".newcon2 .xj2_echart .echart1 .zhu .jindu").animate(
        { height: "60%" },
        650
      );
      $(".zhian1_bot .jindu").animate({ width: "60%" }, 650);
    },

    getmap() {
      console.log(5555555555);
      $("map").append(
        '<area shape="poly" alt="" coords="614,151,762,202,860,220,914,281,878,484,591,404,634,278"   name="xiangmi" /><area shape="poly" alt="" coords="427,270,401,351,587,400,617,315"   name="donghai" /><area shape="poly" alt="" coords="409,160,435,198,437,229,430,263,615,309,625,278,603,148,523,122,484,145"   name="nongyuan" /><area shape="poly" alt="" coords="517,113,484,136,457,98,466,77,531,80,630,92,605,141"   name="qiaoxiang" /><area shape="poly" alt="" coords="636,94,615,144,742,189,767,195,858,211,814,161,812,135"   name="xiangmei" /><area shape="poly" alt="" coords="401,163,429,199,426,261,395,351,355,340,347,277,312,211,270,172,230,146,258,152,315,169,359,173"   name="zhuyuan" /><area shape="poly" alt="" coords="116,128,228,140,294,154,343,166,392,159,477,140,451,101,449,92,461,74,428,74,374,52,328,38,287,2,278,6,273,17,255,28,245,48,250,61,240,62,218,57,207,64,179,66,166,89"   name="xiangan" /><area shape="poly" alt="" coords="286,196,232,232,188,301,345,340,340,273,302,210"   name="zhulin" /><area shape="poly" alt="" coords="116,132,224,149,282,192,227,228,185,301,51,265,12,243,12,239"   name="xiangling" />'
      );

      $(document).on("mouseover", "area", function () {
        // console.log(this);
        $("img[data-name=" + $(this).attr("name") + "]").css("opacity", 1);
        $(".tit_" + $(this).attr("name"))
          .children(".t")
          .addClass("tit_acb");
        $(".tit_" + $(this).attr("name"))
          .children(".wave-tips")
          .addClass("ac_wave_tips");
        $(".tit_" + $(this).attr("name"))
          .children(".ani_bgf")
          .children()
          .addClass("ac_t_ico")
          .removeClass("ani_bg");
      });
      $(document).on("mouseout", "area", function () {
        $("img[data-name=" + $(this).attr("name") + "]").css("opacity", 0);
        $(".tit_" + $(this).attr("name"))
          .children(".t")
          .removeClass("tit_acb");
        $(".tit_" + $(this).attr("name"))
          .children(".wave-tips")
          .removeClass("ac_wave_tips");
        $(".tit_" + $(this).attr("name"))
          .children(".ani_bgf")
          .children()
          .addClass("ani_bg")
          .removeClass("ac_t_ico");
      });
    },

    getnum() {
      $.fn.countTo = function (options) {
        options = options || {};

        console.log(456);
        return $(this).each(function () {
          // set options for current element
          var settings = $.extend(
            {},
            $.fn.countTo.defaults,
            {
              from: $(this).data("from"),
              to: $(this).data("to"),
              speed: $(this).data("speed"),
              refreshInterval: $(this).data("refresh-interval"),
              decimals: $(this).data("decimals"),
            },
            options
          );

          // how many times to update the value, and how much to increment the value on each update
          var loops = Math.ceil(settings.speed / settings.refreshInterval),
            increment = (settings.to - settings.from) / loops;

          // references & variables that will change with each update
          var self = this,
            $self = $(this),
            loopCount = 0,
            value = settings.from,
            data = $self.data("countTo") || {};

          $self.data("countTo", data);

          // if an existing interval can be found, clear it first
          if (data.interval) {
            clearInterval(data.interval);
          }
          data.interval = setInterval(updateTimer, settings.refreshInterval);

          // initialize the element with the starting value
          render(value);

          function updateTimer() {
            value += increment;
            loopCount++;

            render(value);

            if (typeof settings.onUpdate == "function") {
              settings.onUpdate.call(self, value);
            }

            if (loopCount >= loops) {
              // remove the interval
              $self.removeData("countTo");
              clearInterval(data.interval);
              value = settings.to;

              if (typeof settings.onComplete == "function") {
                settings.onComplete.call(self, value);
              }
            }
          }

          function render(value) {
            var formattedValue = settings.formatter.call(self, value, settings);
            $self.html(formattedValue);
          }
        });
      };

      $.fn.countTo.defaults = {
        from: 0, // the number the element should start at
        to: 0, // the number the element should end at
        speed: 1000, // how long it should take to count between the target numbers
        refreshInterval: 100, // how often the element should be updated
        decimals: 0, // the number of decimal places to show
        formatter: formatter, // handler for formatting the value before rendering
        onUpdate: null, // callback method for every time the element is updated
        onComplete: null, // callback method for when the element finishes updating
      };

      function formatter(value, settings) {
        return value.toFixed(settings.decimals);
      }

      // custom formatting example
      $("#count-number").data("countToOptions", {
        formatter: function (value, options) {
          return value
            .toFixed(options.decimals)
            .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
        },
      });

      // start all the timers
      $(".timer").each(count);
      function count(options) {
        var $this = $(this);
        options = $.extend(
          {},
          options || {},
          $this.data("countToOptions") || {}
        );
        $this.countTo(options);
      }
    },

    getjindu() {
      // var _this = this;
      var myChart = echarts.init(document.getElementById("jindu"));
      var option = {
        grid: {
          // 直角坐标系内绘图网格
          left: "10", //grid 组件离容器左侧的距离,
          //left的值可以是80这样具体像素值，
          //也可以是'80%'这样相对于容器高度的百分比
          top: "0",
          right: "10",
          bottom: "0",
          containLabel: true, //gid区域是否包含坐标轴的刻度标签。为true的时候，
          // left/right/top/bottom/width/height决定的是包括了坐标轴标签在内的
          //所有内容所形成的矩形的位置.常用于【防止标签溢出】的场景
        },
        xAxis: {
          //直角坐标系grid中的x轴,
          //一般情况下单个grid组件最多只能放上下两个x轴,
          //多于两个x轴需要通过配置offset属性防止同个位置多个x轴的重叠。
          type: "value", //坐标轴类型,分别有：
          //'value'-数值轴；'category'-类目轴;
          //'time'-时间轴;'log'-对数轴
          splitLine: {
            show: false,
          }, //坐标轴在 grid 区域中的分隔线
          axisLabel: {
            show: false,
          }, //坐标轴刻度标签
          axisTick: {
            show: false,
          }, //坐标轴刻度
          axisLine: {
            show: false,
          }, //坐标轴轴线
        },
        yAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            inside: true,
            splitNumber: 50,
            boundaryGap: [20, 20],
            textStyle: {
              color: "#fff",
              verticalAlign: "bottom",
              align: "left",
              padding: [200, 0, 10, 0],
            },
          },
          data: ["户外屏公益广告更换", "农贸市场升级改造", "厕所革命"], //类目数据，在类目轴（type: 'category'）中有效。
          //如果没有设置 type，但是设置了axis.data,则认为type 是 'category'。
        },
        series: [
          //系列列表。每个系列通过 type 决定自己的图表类型
          {
            name: "%", //系列名称
            type: "bar", //柱状、条形图
            barWidth: 12, //柱条的宽度,默认自适应
            data: [20, 40, 60], //系列中数据内容数组
            label: {
              //图形上的文本标签
              show: true,
              position: "right", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}{a}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
            itemStyle: {
              //图形样式
              normal: {
                //normal 图形在默认状态下的样式;
                //emphasis图形在高亮状态下的样式
                barBorderRadius: 3, //柱条圆角半径,单位px.
                //此处统一设置4个角的圆角大小;
                //也可以分开设置[10,10,10,10]顺时针左上、右上、右下、左下
                // color: {
                // 	x: 0,
                // 	y: 1,
                // 	x2: 0,
                // 	y2: 0,
                // 	colorStops: [{
                // 		offset: 0,
                // 		color: '#fed032' // 0% 处的颜色
                // 	}, {
                // 		offset: 1,
                // 		color: '#ffa800' // 100% 处的颜色
                // 	}],
                // 	global: false // 缺省为 false
                // }

                decal: {
                  symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                  symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                  symbolKeepAspect: true, //是否保持图案的长宽比
                  color: "#3bb0e6",
                  backgroundColor: "",
                  dashArrayX: 1,
                  dashArrayY: 2,
                  rotation: 45, //图案的整体旋转角度（弧度制）
                  maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                  maxTileHeight: 512,
                },
                color: function (params) {
                  let num = myColor.length; //得到myColor颜色数组的长度
                  return myColor[params.dataIndex % num]; //返回颜色数组中的一个对应的颜色值
                },
              },
            },
            zlevel: 1, //柱状图所有图形的 zlevel 值,
            //zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
          },
          {
            name: "进度条背景",
            type: "bar",
            label: {
              //图形上的文本标签
              show: false,
              position: "right", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              // formatter: '{c}{a}',//标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 16, //标签字号
              fontFamily: "RUI",
            },
            barGap: "-100%", //不同系列的柱间距离，为百分比。
            // 在同一坐标系上，此属性会被多个 'bar' 系列共享。
            // 此属性应设置于此坐标系中最后一个 'bar' 系列上才会生效，
            //并且是对此坐标系中所有 'bar' 系列生效。
            barWidth: 12,
            data: [100, 100, 100, 100, 100],
            color: "#38404b", //柱条颜色

            itemStyle: {
              normal: {
                barBorderRadius: 3,
              },
            },
          },
        ],
      };

      let myColor = ["#4fc0fb", "#3be0ad", "#f3a94a", "#F8B448", "#8B78F6"];
      myChart.setOption(option);
    },

    getjindu1() {
      // var _this = this;
      var myChart = echarts.init(document.getElementById("jindu1"));
      var option = {
        grid: {
          // 直角坐标系内绘图网格
          left: "10", //grid 组件离容器左侧的距离,
          //left的值可以是80这样具体像素值，
          //也可以是'80%'这样相对于容器高度的百分比
          top: "0",
          right: "10",
          bottom: "0",
          containLabel: true, //gid区域是否包含坐标轴的刻度标签。为true的时候，
          // left/right/top/bottom/width/height决定的是包括了坐标轴标签在内的
          //所有内容所形成的矩形的位置.常用于【防止标签溢出】的场景
        },
        xAxis: {
          //直角坐标系grid中的x轴,
          //一般情况下单个grid组件最多只能放上下两个x轴,
          //多于两个x轴需要通过配置offset属性防止同个位置多个x轴的重叠。
          type: "value", //坐标轴类型,分别有：
          //'value'-数值轴；'category'-类目轴;
          //'time'-时间轴;'log'-对数轴
          splitLine: {
            show: false,
          }, //坐标轴在 grid 区域中的分隔线
          axisLabel: {
            show: false,
          }, //坐标轴刻度标签
          axisTick: {
            show: false,
          }, //坐标轴刻度
          axisLine: {
            show: false,
          }, //坐标轴轴线
        },
        yAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            inside: true,
            splitNumber: 50,
            boundaryGap: [20, 20],
            textStyle: {
              color: "#fff",
              verticalAlign: "bottom",
              align: "left",
              padding: [200, 0, 10, 0],
            },
          },
          data: ["'双随机、一公开'台账进度"], //类目数据，在类目轴（type: 'category'）中有效。
          //如果没有设置 type，但是设置了axis.data,则认为type 是 'category'。
        },
        series: [
          //系列列表。每个系列通过 type 决定自己的图表类型
          {
            name: "%", //系列名称
            type: "bar", //柱状、条形图
            barWidth: 12, //柱条的宽度,默认自适应
            data: [40], //系列中数据内容数组
            label: {
              //图形上的文本标签
              show: true,
              position: "right", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              formatter: "{c}{a}", //标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 15, //标签字号
              fontFamily: "RUI",
            },
            aria: {
              enabled: true,
              decal: {
                show: true,
              },
            },
            itemStyle: {
              //图形样式
              normal: {
                //normal 图形在默认状态下的样式;
                //emphasis图形在高亮状态下的样式
                barBorderRadius: 3, //柱条圆角半径,单位px.
                //此处统一设置4个角的圆角大小;
                //也可以分开设置[10,10,10,10]顺时针左上、右上、右下、左下
                // 									color: {
                //                     type: 'linear',
                //                     x: 0,
                //                     y: 0,
                //                     x2: 0,
                //                     y2: 1,
                //                     colorStops: [{
                //                           offset: 1, color: '#FFCC85' // 0% 处的颜色
                //                     }, {
                //                           offset: 0, color: '#FFA633' // 100% 处的颜色
                //                     }],
                //                     globalCoord: false // 缺省为 false
                // },

                // color: function(params) {
                // 	let num = myColor.length; //得到myColor颜色数组的长度
                // 	return myColor[params.dataIndex % num]; //返回颜色数组中的一个对应的颜色值
                // },

                decal: {
                  symbol: "rect", //贴花的图案，属性值也可以是数组表示循环使用，circle/rect/roundRect/triangle/diamond/pin/arrow/none
                  symbolSize: 1, //表示占图案区域的百分比，取值范围：0-1
                  symbolKeepAspect: true, //是否保持图案的长宽比
                  color: "#3bb0e6",
                  backgroundColor: "#53c8fd",
                  dashArrayX: 1,
                  dashArrayY: 2,
                  rotation: 45, //图案的整体旋转角度（弧度制）
                  maxTileWidth: 512, //生成的图案在未重复之前的宽度上限。通常不需要设置该值，当你发现图案在重复的时候出现不连续的接缝时，可以尝试提高该值
                  maxTileHeight: 512,
                },
              },
            },
            zlevel: 1, //柱状图所有图形的 zlevel 值,
            //zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
          },
          {
            name: "进度条背景",
            type: "bar",
            label: {
              //图形上的文本标签
              show: false,
              position: "right", //标签的位置
              offset: [0, 0], //标签文字的偏移，此处表示向上偏移40
              // formatter: '{c}{a}',//标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
              color: "#fff", //标签字体颜色
              fontSize: 16, //标签字号
              fontFamily: "RUI",
            },
            barGap: "-100%", //不同系列的柱间距离，为百分比。
            // 在同一坐标系上，此属性会被多个 'bar' 系列共享。
            // 此属性应设置于此坐标系中最后一个 'bar' 系列上才会生效，
            //并且是对此坐标系中所有 'bar' 系列生效。
            barWidth: 12,
            data: [100, 100, 100, 100, 100],
            color: "#38404b", //柱条颜色

            itemStyle: {
              normal: {
                barBorderRadius: 3,
              },
            },
          },
        ],
      };

      // let myColor = ['#53c8fd', '#3be0ad', '#f3a94a', '#F8B448', '#8B78F6']
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../pages/chengshiguanli/chengshiguanli.scss";
</style>