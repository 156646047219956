<template>
  <div class="gengxin" id="app">
    <headerVue ref="back">法律审核</headerVue>

    <div class="container">
      <div class="con_left animate__animated animate__fadeInLeft">
        <div class="con_content">
          <div class="con_c__title">
            <i class="t_text">法制审核</i>
          </div>
          <div class="con_c__panle">
            <div id="pie1" style="height: 100%; width: 100%"></div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title">
            <i class="t_text">案卷评查</i>
          </div>
          <div class="con_c__panle case_file">
            <div class="case_file_tab">
              <i class="case_aci_tab">一季度</i>
              <i>二季度</i>
              <i>三季度</i>
              <i>四季度</i>
            </div>
            <div class="case_file_charts">
              <div id="pie2" style="height: 100%; width: 50%"></div>
              <div id="pie3" style="height: 100%; width: 50%"></div>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title">
            <i class="t_text">案件申诉</i>
          </div>
          <div class="con_c__panle">
            <div id="bar1" style="height: 100%; width: 100%"></div>
          </div>
        </div>
      </div>
      <div class="con_center">
        <div class="map_main animate__animated animate__zoomIn" id="map_main">
          <div class="map_box">
            <img
              src="../../images/map/map_bg.png"
              usemap="#Map"
              alt=""
              srcset=""
            />
            <map name="Map" id="Map"></map>
          </div>
          <div class="map_box_tit">
            <div class="tit tit_xiangmi">
              <div class="t">香蜜社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <!-- <span></span> -->
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangmei">
              <div class="t">香梅社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_qiaoxiang">
              <div class="t">侨香社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_nongyuan">
              <div class="t">农园社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_donghai">
              <div class="t">东海社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_zhuyuan">
              <div class="t">竹园社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_zhulin">
              <div class="t">竹林社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangan">
              <div class="t">香安社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangling">
              <div class="t">香岭社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px; height: 8px">
                <b></b>
                <b></b>
              </div>
            </div>
          </div>
          <div class="map_box_tip">
            <img src="../../images/map/donghai.png" data-name="donghai" />
            <img src="../../images/map/nongyuan.png" data-name="nongyuan" />
            <img src="../../images/map/qiaoxiang.png" data-name="qiaoxiang" />
            <img src="../../images/map/xiangan.png" data-name="xiangan" />
            <img src="../../images/map/xiangling.png" data-name="xiangling" />
            <img src="../../images/map/xiangmei.png" data-name="xiangmei" />
            <img src="../../images/map/zhulin.png" data-name="zhulin" />
            <img src="../../images/map/zhuyuan.png" data-name="zhuyuan" />
            <img src="../../images/map/xiangmi.png" data-name="xiangmi" />
          </div>
        </div>
        <div class="c_circle">
          <div class="c_circle1"></div>
          <div class="c_circle2"></div>
          <div class="c_circle3"></div>
          <div class="c_circle4"></div>
        </div>
      </div>
      <div class="con_right animate__animated animate__fadeInRight">
        <div class="con_content">
          <div class="con_c__title">
            <i class="t_text">自由裁量</i>
          </div>
          <div class="con_c__panle clip_con">
            <div class="clip_bg c_bg1">
              <div class="clip_cir"></div>
              <i class="t_text2">
                <span class="timer" data-to="1" data-speed="1500">0</span>
                <span class="timer" data-to="6" data-speed="1500">0</span>
                <span class="timer" data-to="0" data-speed="1500">0</span>
              </i>
              <span>自由裁量以内</span>
            </div>
            <div class="clip_bg c_bg2">
              <div class="clip_cir2"></div>
              <i class="t_text3">
                <span class="timer" data-to="6" data-speed="1500">0</span>
                <span class="timer" data-to="0" data-speed="1500">0</span>
              </i>
              <span>自由裁量以外</span>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title">
            <i class="t_text">执法监督</i>
          </div>
          <div class="con_c__panle">
            <div id="bar2" style="height: 100%; width: 100%"></div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title">
            <i class="t_text">综合查询</i>
          </div>
          <div class="con_c__panle z__select_g">
            <div class="z__selecti">
              <i
                ><span class="timer" data-to="2" data-speed="1500">2</span
                ><span class="timer" data-to="3" data-speed="1500">3</span></i
              >
              <div class="ti_i t_ico1">立案</div>
            </div>
            <div class="z__selecti">
              <i
                ><span class="timer" data-to="2" data-speed="1500">2</span
                ><span class="timer" data-to="4" data-speed="1500">4</span></i
              >
              <div class="ti_i t_ico2">投诉</div>
            </div>
            <div class="z__selecti">
              <i
                ><span class="timer" data-to="3" data-speed="1500">3</span
                ><span class="timer" data-to="6" data-speed="1500">6</span></i
              >
              <div class="ti_i t_ico3">处罚</div>
            </div>
            <div class="z__selecti">
              <i
                ><span class="timer" data-to="5" data-speed="1500">5</span
                ><span class="timer" data-to="1" data-speed="1500">1</span></i
              >
              <div class="ti_i t_ico4">结案</div>
            </div>
            <div class="z__selecti">
              <i
                ><span class="timer" data-to="2" data-speed="1500">2</span
                ><span class="timer" data-to="8" data-speed="1500">8</span></i
              >
              <div class="ti_i t_ico5">卫片执法</div>
            </div>
            <div class="z__selecti">
              <i
                ><span class="timer" data-to="3" data-speed="1500">3</span
                ><span class="timer" data-to="2" data-speed="1500">2</span></i
              >
              <div class="ti_i t_ico6">拆除</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footerVue></footerVue>
    <div class="bot_line"></div>
  </div>
</template>

<script>
import "../../js/map.js";
import $ from "jquery";
import "../../js/num.js";
import footerVue from "../../components/footerBar.vue";
import headerVue from "../../components/top.vue";
const echarts = require("echarts");
export default {
  name: "falvshenhe",
  components: {
    headerVue,
    footerVue,
  },
  data() {
    return {
      data1: {
        value: 89,
        label: "数量",
        color: "#38c0ff",
        max: 100,
      },
      data2: {
        value: 84.5,
        label: "平均分",
        color: "#ffa838",
        max: 100,
      },
      chartsData:{
        v1: ["立案阶段", "结案阶段", "办理阶段", "调查阶段"],
        v2: [260, 228, 189, 143],
      },
      colors:[
      "rgba(56, 192, 255",
      "rgba(244, 162, 57",
      "rgba(77, 200, 135",
      "rgba(214, 226, 232",
      ],
      barY : [],

      
      
    };
  },
  mounted() {
    this.getchart1();
    this.getchart2();
    this.getchart4();
    this.getchart3(this.data1, "pie2");
    this.getchart3(this.data2, "pie3");
    this.getmap();
    this.getnum()
  },
  methods: {
    fontSize(res) {
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      return (res * clientWidth) / 1920;
    },

    getchart1() {
      var myChart1 = echarts.init(document.getElementById("pie1"));
      var option = {
        backgroundColor: "transparent",
        animation: true,
        title: {
          text: 204,
          subtext: "总计",
          x: "center",
          y: "center",
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(26),
            fontFamily: "RUI",
            fontWeight: "normal",
            align: "center",
          },
          subtextStyle: {
            color: "#fff",
            fontSize: this.fontSize(16),
            fontWeight: "normal",
            align: "center",
          },
        },
        series: [
          {
            type: "pie",
            center: ["50%", "52%"],
            radius: ["50%", "63%"],
            color: ["#ffa838", "#d6e2e8", "#38c0ff"],
            startAngle: 195,
            labelLine: {
              length: this.fontSize(10),
              length2: this.fontSize(20),
              lineStyle: {
                width: 1,
                cap: "round",
              },
            },
            label: {
              normal: {
                formatter: "{per|{c}}\n{b|{b}}",
                backgroundColor: "rgba(255, 147, 38, 0)",
                borderColor: "transparent",
                borderRadius: 4,
                rich: {
                  b: {
                    color: "#cfcfd0",
                    fontSize: this.fontSize(14),
                    lineHeight: 24,
                  },
                  per: {
                    color: "auto",
                    fontSize: this.fontSize(24),
                    borderRadius: 2,
                    fontFamily: "RUI",
                    align: "center",
                  },
                },
                textStyle: {
                  color: "#fff",
                  fontSize: 12,
                },
              },
            },
            data: [
              {
                name: "规土类案件",
                value: 63,
              },
              {
                name: "城管类案件",
                value: 36,
              },
              {
                name: "投诉类案件",
                value: 105,
              },
            ],
          },
          {
            type: "pie",
            center: ["50%", "52%"],
            radius: ["43%", "44%"],
            label: {
              show: false,
            },
            data: [
              {
                value: 78,
                name: "内圈",
                itemStyle: {
                  normal: {
                    color: "rgba(56, 192, 255,.2)",
                  },
                },
              },
            ],
          },
        ],
      };
      myChart1.setOption(option);
    },

    getchart2() {
      var myChart1 = echarts.init(document.getElementById("bar1"));
      var option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "15%",
          right: "8%",
          left: "3%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: [
          {
            name: "月",
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(3),
            type: "category",
            data: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ],
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
              textStyle: {
                fontSize: this.fontSize(12),
              },
            },
          },
        ],
        yAxis: [
          {
            name: "数量",
            nameTextStyle: {
              fontSize: this.fontSize(12),
              color: "rgba(255,255,255,.5)",
            },
            nameGap: this.fontSize(12),
            axisLabel: {
              formatter: "{value}",
              color: "rgba(255,255,255,.5)",
              margin: this.fontSize(12),
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "#38c0ff",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255,255,255,0.12)",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: [40, 45, 77, 23, 55, 88, 56, 89, 52, 74, 47, 32],
            barWidth: this.fontSize(12),
            itemStyle: {
              color: "#38c0ff",
            },
            label: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
                color: "#ffffff",
                fontFamily: "RUI",
                position: "top",
              },
              itemStyle: {
                color: "#ffa838",
              },
            },
          },
        ],
      };

      myChart1.setOption(option);
    },

    // 案卷评查
    getchart3(data,id) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        series: [
          {
            name: data.label,
            type: "gauge",
            z: 1,
            min: 0,
            max: 100,
            splitNumber: 10,
            radius: "80%",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                width: 1,
                color: [[1, "#4f5259"]],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
            },
            detail: {
              show: false,
            },
            data: [],
          },
          {
            name: data.label,
            type: "gauge",
            z: 1,
            min: 0,
            max: 100,
            radius: "50%",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                width: 1,
                color: [[1, "#4f5259"]],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
              length: 15,
            },
            detail: {
              show: false,
            },
            data: [],
          },
          {
            name: data.label,
            type: "gauge",
            z: 2,
            min: 0,
            max: 100,
            radius: "70%",
            axisLine: {
              // 坐标轴线
              show: true,
              lineStyle: {
                width: this.fontSize(10),
                color: [
                  [data.value / data.max, data.color],
                  [1, "#4f5359"],
                ],
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              // 分隔线
              show: false,
            },
            title: {
              color: "rgba(255,255,255,.7)",
              fontSize: this.fontSize(16),
              offsetCenter: [0, "80%"],
            },
            detail: {
              offsetCenter: [0, "0%"],
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "#FFFFFF",
                fontSize: this.fontSize(26),
                fontFamily: "RUI",
              },
            },
            pointer: {
              show: false,
            },
            data: [
              {
                value: data.value,
                name: data.label,
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },

    // 执法监督
    getchart4(){
        for (var i = 0; i < this.chartsData.v1.length; i++) {
      var x = i;
      if (x > this.colors.length - 1) {
        x = this.colors.length - 1;
      }
      var data = {
        name: this.chartsData.v1[i],
        color: this.colors[x] + ")",
        value: this.chartsData.v2[i],
        itemStyle: {
          normal: {
            show: true,
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              1,
              0,
              [
                {
                  offset: 0,
                  color: this.colors[x] + ", 0.3)",
                },
                {
                  offset: 1,
                  color: this.colors[x] + ", 1)",
                },
              ],
              false
            ),
          },
        },
      };
      this.barY.push(data);
    }

    var myChart1 = echarts.init(document.getElementById('bar2'))
    var option = {
      grid: {
        left: "8%",
        right: "8%",
        bottom: "0%",
        top: "8%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "none",
        },
        formatter: function (params) {
          return (
            params[0].name +
            "<br/>" +
            "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
            params[0].seriesName +
            " : " +
            Number(
              params[0].value
            ).toLocaleString() +
            "<br/>"
          );
        },
      },
      backgroundColor: "transparent",
      xAxis: {
        show: false,
        type: "value",
      },
      yAxis: [
        {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: "rgba(255,255,255,.7)",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: this.chartsData.v1,
        },
        {
          type: "category",
          inverse: true,
          axisTick: "none",
          axisLine: "none",
          show: true,
          axisLabel: {
            margin: 15,
            textStyle: {
              color: "#ffffff",
              fontSize: this.fontSize(18),
              fontFamily: "RUI"
            },
          },
          data: this.chartsData.v2,
        },
      ],
      series: [
        {
          name: "执法监督",
          type: "bar",
          zlevel: 1,
          barWidth: this.fontSize(12),
          data: this.barY
        },
        {
          name: "背景",
          type: "bar",
          barWidth: this.fontSize(12),
          barGap: "-100%",
          data: [300, 300, 300, 300],
          itemStyle: {
            normal: {
              color: "rgba(255,255,255,.1)",
            },
          },
        },
      ],
    };
    myChart1.setOption(option);




    },

    getmap(){
        $('map').append('<area shape="poly" alt="" coords="614,151,762,202,860,220,914,281,878,484,591,404,634,278"   name="xiangmi" /><area shape="poly" alt="" coords="427,270,401,351,587,400,617,315"   name="donghai" /><area shape="poly" alt="" coords="409,160,435,198,437,229,430,263,615,309,625,278,603,148,523,122,484,145"   name="nongyuan" /><area shape="poly" alt="" coords="517,113,484,136,457,98,466,77,531,80,630,92,605,141"   name="qiaoxiang" /><area shape="poly" alt="" coords="636,94,615,144,742,189,767,195,858,211,814,161,812,135"   name="xiangmei" /><area shape="poly" alt="" coords="401,163,429,199,426,261,395,351,355,340,347,277,312,211,270,172,230,146,258,152,315,169,359,173"   name="zhuyuan" /><area shape="poly" alt="" coords="116,128,228,140,294,154,343,166,392,159,477,140,451,101,449,92,461,74,428,74,374,52,328,38,287,2,278,6,273,17,255,28,245,48,250,61,240,62,218,57,207,64,179,66,166,89"   name="xiangan" /><area shape="poly" alt="" coords="286,196,232,232,188,301,345,340,340,273,302,210"   name="zhulin" /><area shape="poly" alt="" coords="116,132,224,149,282,192,227,228,185,301,51,265,12,243,12,239"   name="xiangling" />');

  $(document).on('mouseover', 'area', function() {
      // console.log(this);
      $("img[data-name=" + $(this).attr('name') + "]").css('opacity', 1);
      $(".tit_"+$(this).attr('name')).children(".t").addClass("tit_acb");
      $(".tit_"+$(this).attr('name')).children(".wave-tips").addClass("ac_wave_tips");
      $(".tit_"+$(this).attr('name')).children(".ani_bgf").children().addClass("ac_t_ico").removeClass("ani_bg");
  })
  $(document).on('mouseout', 'area', function() {
      $("img[data-name=" + $(this).attr('name') + "]").css('opacity', 0);
      $(".tit_"+$(this).attr('name')).children(".t").removeClass("tit_acb");
      $(".tit_"+$(this).attr('name')).children(".wave-tips").removeClass("ac_wave_tips");
      $(".tit_"+$(this).attr('name')).children(".ani_bgf").children().addClass("ani_bg").removeClass("ac_t_ico");
  })
    },

    getnum(){
      $.fn.countTo = function (options) {
			options = options || {};
			
			console.log(456)
			return $(this).each(function () {
				// set options for current element
				var settings = $.extend({}, $.fn.countTo.defaults, {
					from: $(this).data('from'),
					to: $(this).data('to'),
					speed: $(this).data('speed'),
					refreshInterval: $(this).data('refresh-interval'),
					decimals: $(this).data('decimals')
				}, options);
	
				// how many times to update the value, and how much to increment the value on each update
				var loops = Math.ceil(settings.speed / settings.refreshInterval),
					increment = (settings.to - settings.from) / loops;
	
				// references & variables that will change with each update
				var self = this,
					$self = $(this),
					loopCount = 0,
					value = settings.from,
					data = $self.data('countTo') || {};
	
				$self.data('countTo', data);
	
				// if an existing interval can be found, clear it first
				if (data.interval) {
					clearInterval(data.interval);
				}
				data.interval = setInterval(updateTimer, settings.refreshInterval);
	
				// initialize the element with the starting value
				render(value);
	
				function updateTimer() {
					value += increment;
					loopCount++;
	
					render(value);
	
					if (typeof (settings.onUpdate) == 'function') {
						settings.onUpdate.call(self, value);
					}
	
					if (loopCount >= loops) {
						// remove the interval
						$self.removeData('countTo');
						clearInterval(data.interval);
						value = settings.to;
	
						if (typeof (settings.onComplete) == 'function') {
							settings.onComplete.call(self, value);
						}
					}
				}
	
				function render(value) {
					var formattedValue = settings.formatter.call(self, value, settings);
					$self.html(formattedValue);
				}
			});
		};
	
		$.fn.countTo.defaults = {
			from: 0, // the number the element should start at
			to: 0, // the number the element should end at
			speed: 1000, // how long it should take to count between the target numbers
			refreshInterval: 100, // how often the element should be updated
			decimals: 0, // the number of decimal places to show
			formatter: formatter, // handler for formatting the value before rendering
			onUpdate: null, // callback method for every time the element is updated
			onComplete: null // callback method for when the element finishes updating
		};
	
		function formatter(value, settings) {
			return value.toFixed(settings.decimals);
		}
	
	
	
		// custom formatting example
		$('#count-number').data('countToOptions', {
			formatter: function (value, options) {
				return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
			}
		});
	
		// start all the timers
		$('.timer').each(count);
		function count(options) {
			var $this = $(this);
			options = $.extend({}, options || {}, $this.data('countToOptions') || {});
			$this.countTo(options);
		}
    }


  },
};
</script>

<style lang="scss" scoped>
@import "../../pages/falvshenhe/falvshenhe.scss";
</style>