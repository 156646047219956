<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    
  },
  methods:{
   
  },

}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #12161f;
  }
  body{
    margin: 0;
    width: calc(100vw * 1920 / 1920);
    height: calc(100vh * 1080 / 1080);
  }
  @font-face {
    font-family: RUI;
    src: url(../src/font/rui.TTF);
  }
  a{
      text-decoration: none;
    }
    p{
      padding: 0;
      margin: 0;
    }
    ul{
      padding: 0;
      margin: 0;
    }
    li{
      list-style: none;
    }
    h1,h2,h3,h4,h5,h6{
      margin: 0;
      padding: 0;
    }
    img{
      margin: 0;
    }

    .bot_line {
    position: absolute;
    z-index: 99;
    bottom: 0.12rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw * 1880 / 1920);
    height: 0.02rem;
    background: url(images/bottom_line.png) no-repeat center;
    background-size: 100% 100%;
}
  
  </style>

