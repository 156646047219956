import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
import login from '../pages/login/login'
import index from '../pages/index/index'
import zhifa from '../pages/zhifa/zhifa'
import cityGengxin from '../pages/cityGengxin/cityGengxin'
import anquanxingshi from '../pages/anquanxingshi/anquanxingshi'
import falvshenhe from '../pages/falvshenhe/falvshenhe'
import guitujiancha from '../pages/guitujiancha/guitujiancha'
import chengqupinzhi from '../pages/chengqupinzhi/chengqupinzhi'
import chengshiguanli from '../pages/chengshiguanli/chengshiguanli'
import falvzhichi from '../pages/falvzhichi/falvzhichi'
import guidaojiaotong from '../pages/guidaojiaotong/guidaojiaotong'
import huanjingbaohu from '../pages/huanjingbaohu/huanjingbaohu'
import shengtaiwenming from '../pages/shengtaiwenming/shengtaiwenming'
import shironghuanjing from '../pages/shironghuanjing/shironghuanjing'
import dashuju from '../pages/dashuju/dashuju'

export default new VueRouter({
    routes: [{
            path: '/',
            redirect: '/login' //默认首页
        },
        {
            path: '/login',
            name: 'name',
            component: login
        },
        {
            path: '/index',
            name: 'index',
            component: index
        },
        {
            path: '/zhifa',
            name: 'zhifa',
            component: zhifa
        },
        {
            path: '/cityGengxin',
            name: 'cityGengxin',
            component: cityGengxin
        },
        {
            path: '/anquanxingshi',
            name: 'anquanxingshi',
            component: anquanxingshi
        },
        {
            path: '/falvshenhe',
            name: 'falvshenhe',
            component: falvshenhe
        },
        {
            path: '/guitujiancha',
            name: 'guitujiancha',
            component: guitujiancha
        },
        {
            path: '/chengqupinzhi',
            name: 'chengqupinzhi',
            component: chengqupinzhi
        },
        {
            path: '/chengshiguanli',
            name: 'chengshiguanli',
            component: chengshiguanli
        },
        {
            path: '/falvzhichi',
            name: 'falvzhichi',
            component: falvzhichi
        },
        {
            path: '/guidaojiaotong',
            name: 'guidaojiaotong',
            component: guidaojiaotong
        },
        {
            path: '/huanjingbaohu',
            name: 'huanjingbaohu',
            component: huanjingbaohu
        },
        {
            path: '/shengtaiwenming',
            name: 'shengtaiwenming',
            component: shengtaiwenming
        },
        {
            path: '/shironghuanjing',
            name: 'shironghuanjing',
            component: shironghuanjing
        },
        {
            path: '/dashuju',
            name: 'dashuju',
            component: dashuju
        },
        
    ]
})