<template>
  <div class="login">
    <div class="login_top animate__animated animate__fadeInDownBig">
      <div class="login_top_left">
        <img src="../../img/login_left_bg.png" alt="" />
      </div>
      <div class="login_top_mid">
        <img src="../../img/login1.png" alt="" />
      </div>
      <div class="login_top_right">
        <img src="../../img/login_right_bg.png" alt="" />
        <img src="../../img/tuichu.png" alt="" />
      </div>
    </div>
    <div class="index_con">
      <img class="jianzhu animate__animated animate__backInDown" src="../../img/jianzhu.png" alt="" />
      <img class="diquan2" src="../../img/diquan2.png" alt="">
      <img class="diquan1" src="../../img/diquan1.png" alt="">
      <img class="diquan" src="../../img/diquan.png" alt="" />

      <div class="index_list">
        <div class="list_left animate__animated animate__bounceInLeft">
          <div class="img" @click="shengtaiwenming"></div>
          <div class="img" @click="huanjingbaohu"></div>
          <div class="img" @click="chengshiguanli"></div>
          <div class="img" @click="guidaojiaotong"></div>
          <div class="img" @click="dashuju"></div>
          <div class="img" @click="chengqupinzhi"></div>
        </div>
        <div class="list_right animate__animated animate__bounceInRight">
          <div class="img" @click="guitu"></div>
          <div class="img" @click="shironghuanjing"></div>
          <div class="img" @click="anquan"></div>
          <div class="img" @click="gengxin"></div>
          <div class="img" @click="zhifa"></div>
          <div class="img" @click="falvshenhe"></div>
          <div class="img" @click="falvzhichi"></div>
        </div>
      </div>
    </div>
    <footerVue></footerVue>
  </div>
</template>
  
  <script>
import footerVue from "../../components/footerBar.vue";
export default {
  name: "index",
  components: {
    footerVue,
  },
  data() {
    return {};
  },
  methods: {
    zhifa() {
      this.$router.push({
        name: "zhifa",
        params: {},
      });
    },
    gengxin() {
      this.$router.push({
        name: "cityGengxin",
        params: {},
      });
    },
    anquan() {
      this.$router.push({
        name: "anquanxingshi",
        params: {},
      });
    },
    falvshenhe() {
      this.$router.push({
        name: "falvshenhe",
        params: {},
      });
    },
    guitu() {
      this.$router.push({
        name: "guitujiancha",
        params: {},
      });
    },
    chengqupinzhi() {
      this.$router.push({
        name: "chengqupinzhi",
        params: {},
      });
    },
    chengshiguanli() {
      this.$router.push({
        name: "chengshiguanli",
        params: {},
      });
    },

    shengtaiwenming() {
      this.$router.push({
        name: "shengtaiwenming",
        params: {},
      });
    },
    huanjingbaohu() {
      this.$router.push({
        name: "huanjingbaohu",
        params: {},
      });
    },
    guidaojiaotong() {
      this.$router.push({
        name: "guidaojiaotong",
        params: {},
      });
    },

    dashuju() {
      this.$router.push({
        name: "dashuju",
        params: {},
      });
    },

    falvzhichi() {
      this.$router.push({
        name: "falvzhichi",
        params: {},
      });
    },

    shironghuanjing() {
      this.$router.push({
        name: "shironghuanjing",
        params: {},
      });
    },

    





  },
};
</script>
  
  <style lang="scss" scoped>
.login {
  position: relative;
  width: calc(100vw * 1920 / 1920);
  height: calc(100vh * 1080 / 1080);
  overflow: hidden;
  background-color: #12161f;
  background: url(../../img/index_bg.png) no-repeat center;
  background-size: cover;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  padding: 15px;
  padding-top: 0;
  &::before {
    content: "";
    position: absolute;
    left: 15px;
    top: calc(100vh * 25 / 1080);
    width: calc(100vw * 120 / 1920);
    height: calc(100vh * 1011 / 1080);
    background: url(../../img/login_left_bg_1.png) no-repeat center;
    background-size: 100% 100%;
  }
  &::after {
    content: "";
    position: absolute;
    right: 15px;
    top: calc(100vh * 25 / 1080);
    width: calc(100vw * 120 / 1920);
    height: calc(100vh * 1011 / 1080);
    background: url(../../img/login_right_bg_1.png) no-repeat center;
    background-size: 100% 100%;
  }
  .login_top {
    margin-left: calc(100vw * 30 / 1920);
    width: 100%;
    height: calc(100vh * 95 / 1080);
    display: flex;
    justify-content: space-between;
    .login_top_left {
      margin-top: calc(100vh * 25 / 1080);
      padding-left: calc(100vw * 120 / 1920);
      width: calc(100vw * 420 / 1920);
      height: calc(100vh * 14 / 1080);
      img {
        width: 100%;
        height: auto;
      }
    }
    .login_top_mid {
      width: calc(100vw * 1035 / 1920);
      height: calc(100vh * 107 / 1080);
      img {
        width: 100%;
        height: auto;
      }
    }
    .login_top_right {
      margin-right: calc(100vw * 50 / 1920);
      margin-top: calc(100vh * 25 / 1080);
      width: calc(100vw * 550 / 1920);
      height: calc(100vh * 40 / 1080);
      display: flex;
      align-items: center;
      img {
        &:first-child {
          width: calc(100vw * 420 / 1920);
          height: calc(100vh * 15 / 1080);
        }
        &:nth-child(2) {
          width: calc(100vw * 130 / 1920);
          height: calc(100vh * 40 / 1080);
        }
      }
    }
  }
  .index_con {
    margin: auto;
    width: calc(100vw * 1900 / 1920);
    height: calc(100vh * 985 / 1080);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .jianzhu {
      position: absolute;
      top: calc(100vh * 70 / 1080);
      display: block;
      margin: auto;
      z-index: 999;
      width: calc(100vw * 1108 / 1920);
      height: auto;
    }
    .diquan {
      position: absolute;
      top: calc(100vh * 70 / 1080);
      margin: auto;
      margin-top: 280px;
      width: auto;
      height: auto;
    }

    .diquan1 {
      position: absolute;
      top: calc(100vh * 0 / 1080);
      margin: auto;
      margin-top: 260px;
      width: auto;
      height: auto;
      -webkit-animation: conCircle 15s linear infinite;
      animation: conCircle 15s linear infinite;
      transform: translateX(0) rotateX(60deg) rotate(0deg) skew(0deg) translateZ(320px);
    }
    .diquan2 {
      position: absolute;
      top: calc(100vh * 130 / 1080);
      margin: auto;
      margin-top: 350px;
      width: auto;
      height: auto;
      -webkit-animation: conCircle2 9s linear infinite;
      animation: conCircle2 9s linear infinite;
    }

  @keyframes conCircle {
  0% {
    transform: translateX(0) rotateX(60deg) rotate(0deg) skew(0deg) translateZ(250px);
  }

  50% {
    transform: translateX(0) rotateX(60deg) rotate(180deg) skew(0deg) translateZ(320px);
  }

  100% {
    transform: translateX(0) rotateX(60deg) rotate(360deg) skew(0deg) translateZ(320px);
  }
}

@keyframes conCircle2 {
  0% {
    transform: translateX(0) rotateX(70deg) rotate(0deg) skew(0deg) translateZ(320px);
  }

  50% {
    transform: translateX(0) rotateX(70deg) rotate(-180deg) skew(0deg) translateZ(320px);
  }

  100% {
    transform: translateX(0) rotateX(70deg) rotate(-360deg) skew(0deg) translateZ(320px);
  }
}

    .index_list {
      width: 100%;
      height: calc(100vh * 680 / 1080);
      display: flex;
      justify-content: space-between;
      margin-top: calc(100vh * 150 / 1080);
      .list_left {
        position: relative;
        width: calc(100vw * 650 / 1920);
        height: calc(100vh * 680 / 1080);
        z-index: 999999;
        .img {
          position: absolute;
          top: 0;
          right: 0;
          width: calc(100vw * 261 / 1920);
          height: calc(100vh * 86 / 1080);
          background: url(../../img/shengtai.png) center no-repeat;
          background-size: contain;
          z-index: 9999;
          cursor: pointer;
          transition: all 0.2s;
          &:hover{
            width: calc(100vw * 288 / 1920);
            height: calc(100vh * 120 / 1080);
            background: url(../../img/shengtai_a.png) center no-repeat;
            background-size: contain;
          }
          &:nth-child(2) {
            top: calc(100vh * 145 / 1080);
            right: calc(100vh * 216 / 1080);
            background: url(../../img/huanjing.png) center no-repeat;
            background-size: contain;
            z-index: 999;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
              
              background: url(../../img/huanjing_a.png) center no-repeat;
              background-size: contain;
            }
          }
          &:nth-child(3) {
            background: url(../../img/chengshi.png) center no-repeat;
            top: calc(100vh * 290 / 1080);
            right: calc(100vh * 300 / 1080);
            background-size: contain;
            z-index: 999;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
              
            background: url(../../img/chengshi_a.png) center no-repeat;
            background-size: contain;
            }
          }
          &:nth-child(4) {
            top: calc(100vh * 290 / 1080);
            right: calc(100vh * 50 / 1080);
            background: url(../../img/guidao.png) center no-repeat;
            background-size: contain;
            z-index: 999;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/guidao_a.png) center no-repeat;
            background-size: contain;
            }
          }
          &:nth-child(5) {
            top: calc(100vh * 420 / 1080);
            right: calc(100vh * 216 / 1080);
            background: url(../../img/dashuju.png) center no-repeat;
            background-size: contain;
            z-index: 999;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/dashuju_a.png) center no-repeat;
            background-size: contain;
            }
          }
          &:nth-child(6) {
            top: calc(100vh * 550 / 1080);
            right: 0;
            background: url(../../img/chengqu.png) center no-repeat;
            background-size: contain;
            z-index: 9999;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/chengqu_a.png) center no-repeat;
            background-size: contain;
            }
          }
        }
      }
      .list_right {
        position: relative;
        width: calc(100vw * 650 / 1920);
        height: calc(100vh * 680 / 1080);
        z-index: 999999;
        .img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9999;
          width: calc(100vw * 261 / 1920);
          height: calc(100vh * 86 / 1080);
          background: url(../../img/guitu.png) center no-repeat;
          background-size: contain;
          cursor: pointer;
          transition: all 0.2s;
          &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/guitu_a.png) center no-repeat;
            background-size: contain;
            }
          &:nth-child(2) {
            top: calc(100vh * 145 / 1080);
            left: calc(100vh * 216 / 1080);
            background: url(../../img/shirong.png) center no-repeat;
            background-size: contain;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/shirong_a.png) center no-repeat;
            background-size: contain;
            }
          }
          &:nth-child(3) {
            top: calc(100vh * 290 / 1080);
            left: calc(100vh * 300 / 1080);
            background: url(../../img/anquan.png) center no-repeat;
            background-size: contain;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/anquan_a.png) center no-repeat;
            background-size: contain;
            }
          }
          &:nth-child(4) {
            top: calc(100vh * 290 / 1080);
            left: calc(100vh * 50 / 1080);
            background: url(../../img/gengxin.png) center no-repeat;
            background-size: contain;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/gengxin_a.png) center no-repeat;
            background-size: contain;
            }
          }
          &:nth-child(5) {
            top: calc(100vh * 420 / 1080);
            left: calc(100vh * 216 / 1080);
            background: url(../../img/zonghe.png) center no-repeat;
            background-size: contain;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/zonghe_a.png) center no-repeat;
            background-size: contain;
            }
          }
          &:nth-child(6) {
            top: calc(100vh * 550 / 1080);
            left: 0;
            background: url(../../img/shenhe.png) center no-repeat;
            background-size: contain;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/shenhe_a.png) center no-repeat;
            background-size: contain;
            }
          }
          &:nth-child(7) {
            top: calc(100vh * 600 / 1080);
            left: -300px;
            background: url(../../img/zhichi.png) center no-repeat;
            background-size: contain;
            &:hover{
              width: calc(100vw * 288 / 1920);
              height: calc(100vh * 120 / 1080);
            background: url(../../img/zhichi_a.png) center no-repeat;
            background-size: contain;
            }
          }
        }
      }
    }
  }
  .support {
    width: 205px;
    height: 18px;
    position: absolute;
    bottom: 1%;
    right: 1%;
    z-index: 99;
    background: url(../../img/support.png) no-repeat center;
    background-size: 100% 100%;
  }
}
</style>