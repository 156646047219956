<template>
  <div class="header_bg animate__animated animate__backInDown">
    <div class="goback" @click="back"></div>
    <i><slot></slot></i>
    <div class="h_rg">
      <div class="date_time">
        <span>{{ date }}</span>
        <b>{{ time }}</b>
      </div>
      <div class="logout"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerVue",
  data() {
    return {
      time: "",
      date: "",
    };
  },
  mounted() {
    this.getTime2();
    
  },
  methods: {
    getTime() {
      var today = new Date();
      var y = today.getFullYear() + ".";
      var month = today.getMonth() + 1 + ".";
      var t = today.getDate() + "";
      var h = "";
      var m = "";
      var s = "";
      if (today.getHours() < 10) {
        h = "0" + today.getHours() + ":";
      } else {
        h = today.getHours() + ":";
      }
      if (today.getMinutes() < 10) {
        m = "0" + today.getMinutes() + ":";
      } else {
        m = today.getMinutes() + ":";
      }
      if (today.getSeconds() < 10) {
        s = "0" + today.getSeconds();
      } else {
        s = today.getSeconds();
      }
      // var day = today.getDay();
      // var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      // var week = weeks[day];
      // this.date_time = "<span>"+y+month+t+"</span><b>"+h+m+s+"</b>"
      this.time = h + m + s;
      this.date = y + month + t;
    },

    getTime2(){
        setInterval(this.getTime,1000);
    },
    back(){
        this.$router.back()
    },

  },
};
</script>

<style lang="scss" scoped>
.header_bg {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 0 20px;
  margin-bottom: 0.2rem;
  width: calc(100vw * 1880 / 1920);
  height: calc(100vh * 40 / 1080);
  background: url(../images/header_bg.png) no-repeat center;
  background-size: 100% 100%;
  box-sizing: border-box;
}
/* .mon_v{border-radius: 0.08rem;width: calc(100vw * 288 / 1920);height: calc(100vh * 150 / 1080);background: url(../images/mon_v.png) no-repeat center;background-size: 100% 100%;cursor: pointer;} */
.header_bg i {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  color: #ffffff;
  font-size: 36px;
  font-style: normal;
  font-family: "RUI";
  text-shadow: 0 0 0.3rem rgba(255, 255, 255, 0.2);
}
.h_rg {
  display: flex;
}
.date_time {
  display: flex;
  flex-direction: column;
  margin-right: 18px;
  justify-content: center;
  align-items: center;
}
.date_time span {
  font-size: 15px;
  color: #ffffff;
  font-family: "RUI";
}
.date_time b {
  font-size: 20px;
  color: #ffffff;
  font-family: "RUI";
}
.logout {
    width: calc(100vw * 130 / 1920);
    height: calc(100vh * 40 / 1080);
    cursor: pointer;
    background: url(../images/log_out.png) no-repeat center;
    background-size: 100% 100%;
}
.goback {
    width: calc(100vw * 130 / 1920);
    height: calc(100vh * 40 / 1080);
    cursor: pointer;
    background: url(../images/go_back.png) no-repeat center;
    background-size: 100% 100%;
}
</style>