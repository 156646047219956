<template>
  <div class="zhifa">
    <headerVue ref="back">综合执法监管</headerVue>
    <div class="zhifa_con">
        <img class="dunpai" src="../../img/dunpai.png" alt="">
        <img class="diquan2" src="../../img/diquan2.png" alt="">
        <!-- <img class="diquan1" src="../../img/diquan1.png" alt=""> -->
        <img class="diquan" src="../../img/diquan.png" alt="" />
        <div class="zhifaCon">
            <div class="zhifaCon_left">
                <div class="left1">
                    <div class="left_title">
                        <p>规土监察</p>
                    </div>
                    <div class="anniu">
                        <div class="anniu1 guitu">
                            <p>规土监察专题系统</p>
                            <img src="../../img/zhifaIcon/guitu.png" alt="">
                        </div>
                    </div>
                </div>

                <div class="left1">
                    <div class="left_title">
                        <p>生态环境</p>
                    </div>
                    <div class="anniu">
                        <div class="anniu1 shengtai">
                            <p>生态文明专题系统</p>
                            <img src="../../img/zhifaIcon/shengtai.png" alt="">
                        </div>
                        <div class="anniu1 huanjing">
                            <p>环境保护专题系统</p>
                            <img src="../../img/zhifaIcon/huanjing.png" alt="">
                        </div>
                    </div>
                </div>

                <div class="left1">
                    <div class="left_title">
                        <p>环卫品质</p>
                    </div>
                    <div class="anniu">
                        <div class="anniu1 chengqu">
                            <p>城区品质专题系统</p>
                            <img src="../../img/zhifaIcon/chengqu.png" alt="">
                        </div>
                        <div class="anniu1 shirong">
                            <p>市容环境专题系统</p>
                            <img src="../../img/zhifaIcon/shirong.png" alt="">
                        </div>
                    </div>
                </div>

                <div class="left1">
                    <div class="left_title">
                        <p>城市更新</p>
                    </div>
                    <div class="anniu">
                        <div class="anniu1 chengshig">
                            <p>城市更新专题系统</p>
                            <img src="../../img/zhifaIcon/chengshig.png" alt="">
                        </div>
                    </div>
                </div>


            </div>
            <div class="zhifaCon_right">
                <div class="left1">
                    <div class="left_title">
                        <p>轨道交通</p>
                    </div>
                    <div class="anniu">
                        <div class="anniu1 guidao">
                            <img src="../../img/zhifaIcon/guidao.png" alt="">
                            <p>轨道交通专题系统</p>
                        </div>
                    </div>
                </div>

                <div class="left1">
                    <div class="left_title">
                        <p>执法事件</p>
                    </div>
                    <div class="anniu">
                        <div class="anniu1 chengguan">
                            <img src="../../img/zhifaIcon/chengguan.png" alt="">
                            <p>城管数据采集系统</p>
                        </div>
                        <div class="anniu1 dashuju">
                            <img src="../../img/zhifaIcon/dashuju.png" alt="">
                            <p>大数据应用专题系统</p>
                        </div>
                    </div>
                </div>

                <div class="left1">
                    <div class="left_title">
                        <p>执法力量</p>
                    </div>
                    <div class="anniu">
                        <div class="anniu1 falvy">
                            <img src="../../img/zhifaIcon/falvy.png" alt="">
                            <p>法律援助专题系统</p>
                        </div>
                        <div class="anniu1 falvs">
                            <img src="../../img/zhifaIcon/falvs.png" alt="">
                            <p>法制审核专题系统</p>
                        </div>
                    </div>
                </div>

                <div class="left1">
                    <div class="left_title">
                        <p>安全管理</p>
                    </div>
                    <div class="anniu">
                        <div class="anniu1 anquan">
                            <img src="../../img/zhifaIcon/anquan.png" alt="">
                            <p>安全管理专题系统</p>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
    <footerVue></footerVue>
    <div class="bot_line"></div>
  </div>
</template>

<script>
import footerVue from "../../components/footerBar.vue";
import headerVue from "../../components/top.vue";
export default {
    name:'zhifa',
    components:{
        headerVue,
        footerVue,
    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
    @import '../../pages/zhifa/zhifa.scss';
</style>