<template>
  <div class="gengxin" id="app">
    <headerVue ref="back">城市更新</headerVue>
    <div class="container">
      <div class="con_left animate__animated animate__fadeInLeft">
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">更新方案</i></div>
          <div class="con_c__panle up_panel">
            <div class="up_panel_shandow">
              <b>香蜜社区</b>
              <i>
                资金预算:
                <span class="timer" data-to="595" data-speed="1500">595</span>
                <span>,</span>
                <span class="timer" data-to="400" data-speed="1500">400</span>
                <b>元</b>
              </i>
            </div>
            <i>福田区香蜜湖城市更新项目意愿征集实施方案</i>
            <div class="up_panel_slider">
              <div class="up_psbg" style="width: 20%;"></div>
              <i>20%</i>
            </div>
            <div class="up_bot_date">
              <i class=""><span class="time_1366"></span>开始日期:2021-10-14</i>
              <i class=""><span class="time_1366"></span>完成日期:--</i>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">实施计划</i></div>
          <div class="con_c__panle up_panel">
            <div class="up_panel_shandow">
              <b>香蜜社区</b>
              <i>
                资金预算:
                <span class="timer" data-to="595" data-speed="1500">595</span>
                <span>,</span>
                <span class="timer" data-to="400" data-speed="1500">400</span>
                <b>元</b>
              </i>
            </div>
            <i>香蜜湖度假村家属楼、香蜜楼城市更新项目</i>
            <div class="up_panel_slider">
              <div class="up_psbg" style="width: 49%;"></div>
              <i>49%</i>
            </div>
            <div class="up_bot_date">
              <i class=""><span class="time_1366"></span>开始日期:2021-10-14</i>
              <i class=""><span class="time_1366"></span>完成日期:--</i>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">意愿征集</i></div>
          <div class="con_c__panle up_panel">
            <div class="up_panel_shandow">
              <b>香蜜社区</b>
              <i>
                资金预算:
                <span class="timer" data-to="5" data-speed="1500">599</span>
                <span>,</span>
                <span class="timer" data-to="4" data-speed="1500">400</span>
                <b>元</b>
              </i>
            </div>
            <i>福田区香蜜湖城市更新项目意愿征集实施方案</i>
            <div class="up_panel_slider">
              <div class="up_psbg" style="width: 36%;"></div>
              <i>36%</i>
            </div>
            <div class="up_bot_date">
              <i class=""><span class="time_1366"></span>开始日期:2021-10-14</i>
              <i class=""><span class="time_1366"></span>完成日期:--</i>
            </div>
          </div>
        </div>
      </div>
      <div class="con_center">
        <div class="map_main animate__animated animate__zoomIn" id="map_main">
          <div class="map_box">
            <img src="../../images/map/map_bg.png" usemap="#Map" alt="" srcset="">
            <map name="Map" id="Map"></map>
          </div>
          <div class="map_box_tit">
            <div class="tit tit_xiangmi">
              <div class="t">香蜜社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <!-- <span></span> -->
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangmei">
              <div class="t">香梅社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_qiaoxiang">
              <div class="t">侨香社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_nongyuan">
              <div class="t">农园社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_donghai">
              <div class="t">东海社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_zhuyuan">
              <div class="t">竹园社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_zhulin">
              <div class="t">竹林社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangan">
              <div class="t">香安社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <b></b>
                <b></b>
              </div>
            </div>
            <div class="tit tit_xiangling">
              <div class="t">香岭社区</div>
              <div class="ani_bgf">
                <div class="ani_bg"></div>
              </div>
              <div class="wave-tips" style="width: 18px;height: 8px;">
                <b></b>
                <b></b>
              </div>
            </div>
          </div>
          <div class="map_box_tip">
            <img src="../../images/map/donghai.png" data-name="donghai">
            <img src="../../images/map/nongyuan.png" data-name="nongyuan">
            <img src="../../images/map/qiaoxiang.png" data-name="qiaoxiang">
            <img src="../../images/map/xiangan.png" data-name="xiangan">
            <img src="../../images/map/xiangling.png" data-name="xiangling">
            <img src="../../images/map/xiangmei.png" data-name="xiangmei">
            <img src="../../images/map/zhulin.png" data-name="zhulin">
            <img src="../../images/map/zhuyuan.png" data-name="zhuyuan">
            <img src="../../images/map/xiangmi.png" data-name="xiangmi">
          </div>
        </div>
        <div class="c_circle">
          <div class="c_circle1"></div>
          <div class="c_circle2"></div>
          <div class="c_circle3"></div>
          <div class="c_circle4"></div>
        </div>
        <div class="con_bot_ta">
          <div class="con_bot_item">
            <i>同意申报的户数/套数</i>
            <ul>
              <li>
                <i class="colors_1"><span class="timer" data-to="8" data-speed="1500"></span><b>户</b></i>
                <b>今日签约</b>
              </li>
              <li>
                <i class="colors_1">
                  <span class="timer" data-to="270" data-speed="1500">270</span>
                  <b>户</b>
                </i>
                <b>累计签约</b>
              </li>
              <li>
                <i class="colors_1">
                  <span class="timer" data-to="8" data-speed="1500">8</span>
                  <b>%</b>
                </i>
                <b>今日签约</b>
              </li>
            </ul>
          </div>
          <div class="con_bot_item">
            <i>同意申报的权利人数</i>
            <ul>
              <li>
                <i class="colors_3">
                  <span class="timer" data-to="12" data-speed="1500">12</span>
                  <b>人</b>
                </i>
                <b>今日签约</b>
              </li>
              <li>
                <i class="colors_3">
                  <span class="timer" data-to="411" data-speed="1500">411</span>
                  <b>人</b>
                </i>
                <b>累计签约</b>
              </li>
            </ul>
          </div>
          <div class="con_bot_item">
            <i>同意申报权人占有的建筑面积</i>
            <ul>
              <li>
                <i class="colors_2">
                  <span class="timer" data-to="692" data-speed="1500">692</span>
                  <span>.</span>
                  <span class="timer" data-to="42" data-speed="1500">42</span>
                  <b>m²</b>
                </i>
                <b>今日签约</b>
              </li>
              <li>
                <i class="colors_2">
                  <span class="timer" data-to="22704" data-speed="1500">22704</span>
                  <span>.</span>
                  <span class="timer" data-to="75" data-speed="1500">75</span>
                  <b>m²</b>
                </i>
                <b>累计签约</b>
              </li>
              <li>
                <i class="colors_2">
                  <span class="timer" data-to="60" data-speed="1500">60</span>
                  <span class="timer" data-to="0" data-speed="1500"></span>
                  <span>.</span>
                  <span class="timer" data-to="12" data-speed="1500">12</span>
                  <b>%</b>
                </i>
                <b>签约率</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="con_right animate__animated animate__fadeInRight">
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">风险评估</i></div>
          <div class="con_c__panle up_panel">
            <div class="up_panel_shandow">
              <b>香蜜社区</b>
              <i>
                资金预算:
                <span class="timer" data-to="595" data-speed="1500">595</span>
                <span>,</span>
                <span class="timer" data-to="400" data-speed="1500">400</span>
                <b>元</b>
              </i>
            </div>
            <i>福田区香蜜湖城市更新项目意愿征集实施方案</i>
            <div class="up_panel_slider">
              <div class="up_psbg" style="width: 100%;"></div>
              <i>100%</i>
            </div>
            <div class="up_bot_date">
              <i class=""><span class="time_1366"></span>开始日期:2021-10-14</i>
              <i class=""><span class="time_1366"></span>完成日期:--</i>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">专项规划</i></div>
          <div class="con_c__panle up_panel">
            <div class="up_panel_shandow">
              <b>香蜜社区</b>
              <i>
                资金预算:
                <span class="timer" data-to="595" data-speed="1500">595</span>
                <span>,</span>
                <span class="timer" data-to="400" data-speed="1500">400</span>
                <b>元</b>
              </i>
            </div>
            <i>福田区香蜜湖城市更新项目意愿征集实施方案</i>
            <div class="up_panel_slider">
              <div class="up_psbg" style="width: 81%;"></div>
              <i>81%</i>
            </div>
            <div class="up_bot_date">
              <i class=""><span class="time_1366"></span>开始日期:2021-10-14</i>
              <i class=""><span class="time_1366"></span>完成日期:--</i>
            </div>
          </div>
        </div>
        <div class="con_content">
          <div class="con_c__title"><i class="t_text">主体遴选</i></div>
          <div class="con_c__panle up_panel">
            <div class="up_panel_shandow">
              <b>香蜜社区</b>
              <i>
                资金预算:
                <span class="timer" data-to="595" data-speed="1500">595</span>
                <span>,</span>
                <span class="timer" data-to="400" data-speed="1500">400</span>
                <b>元</b>
              </i>
            </div>
            <i>福田区香蜜湖城市更新项目意愿征集实施方案</i>
            <div class="up_panel_slider">
              <div class="up_psbg" style="width: 36%;"></div>
              <i>36%</i>
            </div>
            <div class="up_bot_date">
              <i class=""><span class="time_1366"></span>开始日期:2021-10-14</i>
              <i class=""><span class="time_1366"></span>完成日期:--</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerVue></footerVue>
    <div class="bot_line"></div>
  </div>
</template>

<script>
import '../../js/map.js'
import '../../js/num.js'
import $ from "jquery";
import footerVue from "../../components/footerBar.vue";
import headerVue from "../../components/top.vue";
export default {
    name:'cityGengxin',
    components:{
        headerVue,
        footerVue,
    },
    mounted(){
      this.getmap()
      this.getnum()
    },
    methods:{
      getmap(){
        $('map').append('<area shape="poly" alt="" coords="614,151,762,202,860,220,914,281,878,484,591,404,634,278"   name="xiangmi" /><area shape="poly" alt="" coords="427,270,401,351,587,400,617,315"   name="donghai" /><area shape="poly" alt="" coords="409,160,435,198,437,229,430,263,615,309,625,278,603,148,523,122,484,145"   name="nongyuan" /><area shape="poly" alt="" coords="517,113,484,136,457,98,466,77,531,80,630,92,605,141"   name="qiaoxiang" /><area shape="poly" alt="" coords="636,94,615,144,742,189,767,195,858,211,814,161,812,135"   name="xiangmei" /><area shape="poly" alt="" coords="401,163,429,199,426,261,395,351,355,340,347,277,312,211,270,172,230,146,258,152,315,169,359,173"   name="zhuyuan" /><area shape="poly" alt="" coords="116,128,228,140,294,154,343,166,392,159,477,140,451,101,449,92,461,74,428,74,374,52,328,38,287,2,278,6,273,17,255,28,245,48,250,61,240,62,218,57,207,64,179,66,166,89"   name="xiangan" /><area shape="poly" alt="" coords="286,196,232,232,188,301,345,340,340,273,302,210"   name="zhulin" /><area shape="poly" alt="" coords="116,132,224,149,282,192,227,228,185,301,51,265,12,243,12,239"   name="xiangling" />');

  $(document).on('mouseover', 'area', function() {
      // console.log(this);
      $("img[data-name=" + $(this).attr('name') + "]").css('opacity', 1);
      $(".tit_"+$(this).attr('name')).children(".t").addClass("tit_acb");
      $(".tit_"+$(this).attr('name')).children(".wave-tips").addClass("ac_wave_tips");
      $(".tit_"+$(this).attr('name')).children(".ani_bgf").children().addClass("ac_t_ico").removeClass("ani_bg");
  })
  $(document).on('mouseout', 'area', function() {
      $("img[data-name=" + $(this).attr('name') + "]").css('opacity', 0);
      $(".tit_"+$(this).attr('name')).children(".t").removeClass("tit_acb");
      $(".tit_"+$(this).attr('name')).children(".wave-tips").removeClass("ac_wave_tips");
      $(".tit_"+$(this).attr('name')).children(".ani_bgf").children().addClass("ani_bg").removeClass("ac_t_ico");
  })
    },

    getnum(){
      $.fn.countTo = function (options) {
			options = options || {};
			
			console.log(456)
			return $(this).each(function () {
				// set options for current element
				var settings = $.extend({}, $.fn.countTo.defaults, {
					from: $(this).data('from'),
					to: $(this).data('to'),
					speed: $(this).data('speed'),
					refreshInterval: $(this).data('refresh-interval'),
					decimals: $(this).data('decimals')
				}, options);
	
				// how many times to update the value, and how much to increment the value on each update
				var loops = Math.ceil(settings.speed / settings.refreshInterval),
					increment = (settings.to - settings.from) / loops;
	
				// references & variables that will change with each update
				var self = this,
					$self = $(this),
					loopCount = 0,
					value = settings.from,
					data = $self.data('countTo') || {};
	
				$self.data('countTo', data);
	
				// if an existing interval can be found, clear it first
				if (data.interval) {
					clearInterval(data.interval);
				}
				data.interval = setInterval(updateTimer, settings.refreshInterval);
	
				// initialize the element with the starting value
				render(value);
	
				function updateTimer() {
					value += increment;
					loopCount++;
	
					render(value);
	
					if (typeof (settings.onUpdate) == 'function') {
						settings.onUpdate.call(self, value);
					}
	
					if (loopCount >= loops) {
						// remove the interval
						$self.removeData('countTo');
						clearInterval(data.interval);
						value = settings.to;
	
						if (typeof (settings.onComplete) == 'function') {
							settings.onComplete.call(self, value);
						}
					}
				}
	
				function render(value) {
					var formattedValue = settings.formatter.call(self, value, settings);
					$self.html(formattedValue);
				}
			});
		};
	
		$.fn.countTo.defaults = {
			from: 0, // the number the element should start at
			to: 0, // the number the element should end at
			speed: 1000, // how long it should take to count between the target numbers
			refreshInterval: 100, // how often the element should be updated
			decimals: 0, // the number of decimal places to show
			formatter: formatter, // handler for formatting the value before rendering
			onUpdate: null, // callback method for every time the element is updated
			onComplete: null // callback method for when the element finishes updating
		};
	
		function formatter(value, settings) {
			return value.toFixed(settings.decimals);
		}
	
	
	
		// custom formatting example
		$('#count-number').data('countToOptions', {
			formatter: function (value, options) {
				return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
			}
		});
	
		// start all the timers
		$('.timer').each(count);
		function count(options) {
			var $this = $(this);
			options = $.extend({}, options || {}, $this.data('countToOptions') || {});
			$this.countTo(options);
		}
    }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../pages/cityGengxin/gengxin.scss'
</style>