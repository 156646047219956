import $ from 'jquery'
$(function() {
  $('map').append('<area shape="poly" alt="" coords="614,151,762,202,860,220,914,281,878,484,591,404,634,278"   name="xiangmi" /><area shape="poly" alt="" coords="427,270,401,351,587,400,617,315"   name="donghai" /><area shape="poly" alt="" coords="409,160,435,198,437,229,430,263,615,309,625,278,603,148,523,122,484,145"   name="nongyuan" /><area shape="poly" alt="" coords="517,113,484,136,457,98,466,77,531,80,630,92,605,141"   name="qiaoxiang" /><area shape="poly" alt="" coords="636,94,615,144,742,189,767,195,858,211,814,161,812,135"   name="xiangmei" /><area shape="poly" alt="" coords="401,163,429,199,426,261,395,351,355,340,347,277,312,211,270,172,230,146,258,152,315,169,359,173"   name="zhuyuan" /><area shape="poly" alt="" coords="116,128,228,140,294,154,343,166,392,159,477,140,451,101,449,92,461,74,428,74,374,52,328,38,287,2,278,6,273,17,255,28,245,48,250,61,240,62,218,57,207,64,179,66,166,89"   name="xiangan" /><area shape="poly" alt="" coords="286,196,232,232,188,301,345,340,340,273,302,210"   name="zhulin" /><area shape="poly" alt="" coords="116,132,224,149,282,192,227,228,185,301,51,265,12,243,12,239"   name="xiangling" />');

  $(document).on('mouseover', 'area', function() {
      // console.log(this);
      $("img[data-name=" + $(this).attr('name') + "]").css('opacity', 1);
      $(".tit_"+$(this).attr('name')).children(".t").addClass("tit_acb");
      $(".tit_"+$(this).attr('name')).children(".wave-tips").addClass("ac_wave_tips");
      $(".tit_"+$(this).attr('name')).children(".ani_bgf").children().addClass("ac_t_ico").removeClass("ani_bg");
  })
  $(document).on('mouseout', 'area', function() {
      $("img[data-name=" + $(this).attr('name') + "]").css('opacity', 0);
      $(".tit_"+$(this).attr('name')).children(".t").removeClass("tit_acb");
      $(".tit_"+$(this).attr('name')).children(".wave-tips").removeClass("ac_wave_tips");
      $(".tit_"+$(this).attr('name')).children(".ani_bgf").children().addClass("ani_bg").removeClass("ac_t_ico");
  })
});