<template>
  <div class="support"></div>
</template>

<script>
export default {
  name: 'footerVue',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.support{
      width: 205px;
      height: 18px;
      position: absolute;
      bottom: 1.3%;
      right: 1%;
      z-index: 99;
      background: url(../img/support.png) no-repeat center;
      background-size: 100% 100%;
    }
</style>
